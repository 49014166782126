<template>
  <div
    class="audience-select"
    :class="isTextFieldStyle ? 'style-text-field' : ''"
  >
    <div v-if="label" class="audience-select-title">
      {{ label }}
    </div>
    <div v-if="isViewOnly" class="audience-select__view">
      <v-progress-circular
        v-if="isLoading"
        indeterminate
        color="primary"
        :size="20"
        :width="2"
      />
      <span v-else>{{ currentAudience && currentAudience.fullName }}</span>
    </div>
    <v-autocomplete
      v-else
      v-model="currentAudience"
      data-pendo-id="audience-selector"
      :items="items"
      :solo="!isTextFieldStyle"
      :autofocus="origin === 'compareFing' ? true : false"
      return-object
      item-text="fullName"
      :loading="!typesenseAudiencesLoaded"
      id="audience-autocomplete-list--auto"
      :placeholder="placeholder"
      class="audience-select-dropdown"
      item-value="audienceId"
      @update:search-input="handleSearchUpdate($event)"
      @change="handleAudienceChange($event)"
      @click="handleAudienceFocus($event)"
      @blur="handleBlur($event)"
      :disabled="isDisabled"
      :no-filter="true"
      :menu-props="{
        closeOnContentClick: true,
      }"
    >
      <template v-slot:no-data>
        <v-list-item dense class="no-results" v-if="isLoading">
          <v-list-item-content>
            <clip-loader color="#21a7e0" :width="20" :height="20" />
          </v-list-item-content>
        </v-list-item>
        <template v-else-if="search && search.length >= 2">
          <v-list-item dense class="no-results">
            <v-list-item-content>
              <v-list-item-title class="audience-list-content--auto">
                {{ $t('build.step1.storylineFieldNoResult') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            class="audience-list-item--self"
            @click="handleCurrentFP()"
            v-if="
              origin && origin !== '' && (origin === 'OP' || origin === 'Build')
            "
          >
            <v-list-item-content>
              <v-list-item-title>
                <div>
                  <img
                    :src="fingerprintData.logo"
                    alt="Fingerprint-Logo"
                    class="add-icon fingerprint-logo"
                  />
                </div>
                <div class="d-flex flex-column">
                  <span class="add-new-audience-header">
                    {{ $t('upload.useMyFingerprint') }} ({{
                      currentUser.user.fingerPrint
                    }})
                  </span>
                  <span
                    class="add-new-audience-subheader"
                    :style="{
                      'max-width': `${maxWidth ? `${maxWidth}px` : 'auto'}`,
                      'white-space': 'initial',
                    }"
                  >
                    {{ $t('upload.overrideAudienecPreferences') }}
                  </span>
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <span></span>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item v-if="!item.isActive">
          <AudienceItem
            :itemClass="'no-padding'"
            :isInComplete="!item.fingerPrint"
            :fullName="item.fullName"
            :firstName="item.firstName"
            :jobTitle="item.jobTitle"
            :lastName="item.lastName"
            :profileImgS3Path="item.profileImage"
            :email="item.isGeneratedEmail ? '' : item.email"
            :creator="item.creator"
            :ownerId="item.ownerID"
            :maxWidth="maxWidth"
            :companyName="item.companyName"
            :isPrivate="item.isPrivate"
            :isShared="item.isShared"
            :status="item.isActive ? '' : 'Disabled'"
            :isDifferentCompany="item.companyID !== currentUser.user.companyID"
            :isPublicLimited="
              item.isPublicLimited ? item.isPublicLimited : false
            "
            :isGroup="item.isGroup"
            :fingerprintType="
              item.fingerprintType
                ? item.fingerprintType.toLowerCase()
                : 'default'
            "
            :isRecent="item.isRecent"
            :isTrending="item.isTrending"
          />
        </v-list-item>
        <AudienceItem
          v-else
          :itemClass="'no-padding'"
          :isInComplete="!item.fingerPrint"
          :fullName="item.fullName"
          :firstName="item.firstName"
          :jobTitle="item.jobTitle"
          :lastName="item.lastName"
          :profileImgS3Path="item.profileImage"
          :email="item.isGeneratedEmail ? '' : item.email"
          :creator="item.creator"
          :ownerId="item.ownerID"
          :maxWidth="maxWidth"
          :companyName="item.companyName"
          :isPrivate="item.isPrivate"
          :isShared="item.isShared"
          :status="item.isActive ? '' : 'Disabled'"
          :isDifferentCompany="item.companyID !== currentUser.user.companyID"
          :isPublicLimited="item.isPublicLimited ? item.isPublicLimited : false"
          :isGroup="item.isGroup"
          :fingerprintType="
            item.fingerprintType
              ? item.fingerprintType.toLowerCase()
              : 'default'
          "
          :isRecent="item.isRecent"
          :isTrending="item.isTrending"
        />
      </template>
      <template #append-item>
        <v-list-item class="audience-list-item--add" @click="handleAddInternal">
          <v-list-item-content>
            <v-list-item-title>
              <div>
                <v-icon color="#20a7e0" class="add-icon"> mdi-plus </v-icon>
              </div>
              <div class="d-flex flex-column">
                <span class="add-new-audience-header">
                  {{ $t('upload.addFingerprint') }}
                </span>
                <span
                  class="add-new-audience-subheader"
                  :style="{
                    'max-width': `${maxWidth ? `${maxWidth}px` : 'auto'}`,
                    'white-space': 'initial',
                  }"
                >
                  {{ $t('upload.threeWaysToAdd') }}
                </span>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #append v-if="showSearchIcon">
        <v-icon class="icon search-icon">mdi-magnify</v-icon>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import { mapState, mapActions } from 'vuex';
import audienceApi from '../../API/audience-api';
import { fpTypes } from '../../mock/fingerprint';
import AudienceItem from './AudienceItem.vue';
import { setRecentAudience } from '../../utils/api-helper';

export default {
  name: 'AudienceSelectorTypesense',
  components: {
    AudienceItem,
    ClipLoader,
  },
  data() {
    return {
      currentAudience: null,
      search: null,
      searchAudienceTypes: null,
      isDefaultSelectedSearch: false,
      audiences: [],
      realtimeAudiences: [],
      realtimeAudiencesMap: {},
      selectedFromSearchResults: false,
      lastSelectionResults: [],
      lastSelectionValue: null,
      loading: false,
      isFingerPrintRevokedForTheAudience: false,
    };
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    isViewOnly: Boolean,
    origin: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Tailor slides for:',
    },
    maxWidth: {
      type: Number,
      default: 0,
    },
    isTextFieldStyle: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showSearchIcon: {
      type: Boolean,
      default: true,
    },
    addToRealtimeAudienceOnSelect: Boolean,
    extraFields: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'typesenseRealtimeAudiences',
      'typesenseSelfAudience',
      'typesenseAudiencesLoaded',
    ]),
    originalRealtimeAudiences() {
      if (this.origin === 'generate') {
        return this.typesenseRealtimeAudiences.filter(
          (i) => i.type !== 'standard',
        );
      }
      return this.typesenseRealtimeAudiences;
    },
    hasSearchKeyword() {
      if (this.search && this.search !== this.currentAudience?.fullName) {
        return true;
      }
      return false;
    },
    items() {
      if (this.isFingerPrintRevokedForTheAudience) {
        return this.realtimeAudiences;
      }
      if (
        this.hasSearchKeyword ||
        (this.selectedFromSearchResults && this.search)
      ) {
        return this.audiences;
      }
      return this.realtimeAudiences;
    },
    isLoading() {
      return !this.typesenseAudiencesLoaded || this.loading;
    },
    isDisabled() {
      return (
        this.disabled ||
        !this.typesenseAudiencesLoaded ||
        this.isDefaultSelectedSearch
      );
    },
    fingerprintData() {
      const fpData = fpTypes.find(
        (fprint) =>
          fprint.title === this.currentUser?.user?.fingerPrint?.toLowerCase(),
      );
      if (!fpData) {
        return fpTypes?.[0];
      }
      return fpData;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val && val.defaultAudience) {
          console.log('Default audience', val.defaultAudience);

          if (val.defaultAudience === 'SELF') {
            this.handleCurrentFP();
            return;
          }

          const lastDashIndex = val.defaultAudience.lastIndexOf('-');
          const audienceTypes = val.defaultAudience
            .slice(lastDashIndex + 1)
            .split(':');
          const email = val.defaultAudience.slice(0, lastDashIndex);

          this.isDefaultSelectedSearch = true;
          this.searchAudienceTypes = audienceTypes;
          this.handleSearchUpdate(email);
          return;
        }

        if (val !== this.currentAudience && val.type && val.email) {
          this.isDefaultSelectedSearch = true;
          this.searchAudienceTypes = [val.type];
          this.handleSearchUpdate(val.email);
          return;
        }

        this.currentAudience = val;
      },
    },
    currentAudience(newVal, oldVal) {
      if (newVal && !newVal.fingerPrint) {
        this.pullSnapshot(oldVal);
        this.$emit('add', newVal);
      }
    },
    typesenseAudiencesLoaded: {
      immediate: true,
      handler() {
        if (!this.typesenseRealtimeAudiences) {
          return;
        }

        this.realtimeAudiences = this.originalRealtimeAudiences;

        const realtimeAudiencesMap = {};
        this.realtimeAudiences.forEach((a) => {
          realtimeAudiencesMap[a.audienceId] = a;
          if (
            a.companyName.toLowerCase() ===
              this.currentUser.company.name.toLowerCase() ||
            a.companyName.toLowerCase() ===
              this.currentUser.company.displayName.toLowerCase()
          ) {
            realtimeAudiencesMap[a.audienceId].companyID =
              this.currentUser.company.id;
          } else {
            realtimeAudiencesMap[a.audienceId].companyID = '';
          }
        });
        this.realtimeAudiencesMap = realtimeAudiencesMap;

        if (this.value?.defaultAudience === 'SELF') {
          this.handleCurrentFP();
        }
      },
    },
  },
  methods: {
    ...mapActions('users', ['setTypesenseRealtimeAudiences']),

    handleSearchDebounced: debounce((val, _this) => {
      _this.handleSearch(val);
    }, 500),

    handleSearch(val) {
      this.loading = true;
      console.debug('search', val);
      const audienceTypeFilter =
        this.origin === 'generate'
          ? (this.searchAudienceTypes &&
              this.searchAudienceTypes.filter((i) => i !== 'standard')) || [
              'audience',
              'private_audience',
              'user',
            ]
          : this.searchAudienceTypes;
      audienceApi.methods
        .audienceSearch({
          query: val,
          companyId: this.currentUser.user.companyID,
          userId: this.currentUser.user.id,
          realtimeAudiences: this.realtimeAudiencesMap,
          searchAudienceTypes: audienceTypeFilter,
          extraFields: this.extraFields,
          filterByCollection: {
            private_audiences: 'hasFingerPrint:=1',
          },
        })
        .then((audiences) => {
          this.isFingerPrintRevokedForTheAudience = false;
          console.log('search aud type', audienceTypeFilter);
          audiences.forEach((audience) => {
            audience.isPublicLimited = !(
              audience.accessible_to.includes('a__0') ||
              audience.accessible_to.includes(
                `c__${this.currentUser.company.num_id}`,
              ) ||
              audience.accessible_to.includes(
                `u__${this.currentUser.user.num_id}`,
              )
            );
          });
          // When default audience is being set programtically.
          if (this.isDefaultSelectedSearch && audiences.length) {
            // eslint-disable-next-line prefer-destructuring
            this.currentAudience = audiences[0];

            const realtimeAudiences = this.originalRealtimeAudiences.filter(
              (aud) => aud.audienceId !== this.currentAudience.audienceId,
            );
            this.realtimeAudiences = [
              audiences[0],
              ...realtimeAudiences.splice(0, 9),
            ];
            this.handleAudienceChange();
            return;
          }

          // When user is typing keywords
          this.audiences =
            this.origin === 'zentrends'
              ? audiences.filter((aud) => !aud.isPublicLimited)
              : audiences;
          if (
            audienceTypeFilter &&
            audienceTypeFilter.length === 1 &&
            audienceTypeFilter[0] === 'private_audience' &&
            !this.audiences.length
          ) {
            this.isFingerPrintRevokedForTheAudience = true;
          }
        })
        .finally(() => {
          this.loading = false;
          this.searchAudienceTypes = null;
          this.isDefaultSelectedSearch = false;
        });
    },

    // eslint-disable-next-line no-unused-vars
    handleSearchUpdate(search) {
      this.search = search;

      if (this.hasSearchKeyword) {
        this.audiences = [];

        if (this.isDefaultSelectedSearch) {
          this.handleSearch(search);
          return;
        }

        if (search.length >= 2) {
          this.loading = true;
          this.handleSearchDebounced(search, this);
        }
      }
    },

    handleAddInternal() {
      this.$emit('add', this.currentAudience);
    },

    handleCurrentFP() {
      if (this.typesenseSelfAudience && this.realtimeAudiences.length) {
        const realtimeAudiences = this.originalRealtimeAudiences.filter(
          (aud) => aud.audienceId !== this.typesenseSelfAudience.audienceId,
        );
        this.realtimeAudiences = [
          this.typesenseSelfAudience,
          ...realtimeAudiences.splice(0, 9),
        ];
        this.currentAudience = this.typesenseSelfAudience;
        this.handleAudienceChange();
      }
    },

    handleAudienceChange($event) {
      if (this.currentAudience === null || !this.currentAudience.fingerPrint) {
        return;
      }

      this.saveSnapshot();

      this.search = this.currentAudience.fullName;
      this.$emit('change', this.currentAudience);

      if ($event) {
        this.$emit('change:user', this.currentAudience);
      }

      if ($event && this.addToRealtimeAudienceOnSelect) {
        this.addToRealtimeAudience();
      }
    },
    handleAudienceFocus() {
      this.$emit('focus');
    },

    handleBlur() {
      if (!this.currentAudience && !!this.lastSelectionValue) {
        this.pullSnapshot(this.lastSelectionValue);
      }
    },

    saveSnapshot() {
      if (!this.currentAudience) {
        return;
      }

      const isRealtimeAudience =
        this.realtimeAudiencesMap[this.currentAudience.audienceId];

      this.selectedFromSearchResults =
        !isRealtimeAudience &&
        !!this.audiences.find(
          ({ audienceId }) => audienceId === this.currentAudience.audienceId,
        );

      if (this.selectedFromSearchResults) {
        this.lastSelectionResults = cloneDeep(this.audiences);
      } else {
        this.lastSelectionResults = cloneDeep(this.realtimeAudiences);
      }

      this.lastSelectionValue = cloneDeep(this.currentAudience);
    },

    pullSnapshot(audience) {
      this.audiences = this.lastSelectionResults;
      this.$nextTick(() => {
        this.currentAudience = audience;
        this.search = audience?.fullName || null;

        this.saveSnapshot();
      });
    },

    async addToRealtimeAudience() {
      // Update realtime audiences in Vuex Store.
      const isAlreadyExists = this.realtimeAudiences.find(
        ({ audienceId }) => audienceId === this.currentAudience.audienceId,
      );

      if (!isAlreadyExists) {
        const newRealtimeAudiences = [
          ...cloneDeep(this.originalRealtimeAudiences || []),
          { ...this.currentAudience, isRecent: true },
        ];
        const top10 = newRealtimeAudiences.slice(-10);
        this.setTypesenseRealtimeAudiences({ audiences: top10 });
      }

      const currentAudience = {
        ...this.currentAudience,
        id: this.currentAudience.email,
      };
      await setRecentAudience(currentAudience);
    },
  },
};
</script>
<style lang="scss" scoped>
.audience-select {
  align-items: center;
  display: flex;
  flex-direction: row;
  ::v-deep .v-input__slot {
    border-bottom: 0.8px solid #b6b6b6;
    box-shadow: none !important;

    #audience-autocomplete-list {
      padding: 4px 0 0px;
      font-size: 14px;
    }
  }

  .audience-select-title {
    margin-right: 8px;
  }

  .audience-select-dropdown {
    ::v-deep .v-input__control {
      min-height: 38px;
    }
  }

  ::v-deep .v-text-field__details {
    display: none;
  }

  ::v-deep .v-input__slot {
    border-bottom: 0.8px solid #b6b6b6;
  }

  ::v-deep .v-input__icon {
    display: none;
  }
  &__view {
    font-size: 16px;
  }
}

.style-text-field {
  position: relative;

  .audience-select-dropdown {
    border-radius: 0;

    ::v-deep .v-input__control .v-input__slot {
      padding: 0;
    }
  }
}
::v-deep .audience-list-item--add {
  position: sticky;
  bottom: 0;
  background: white;
  border-top: 1px solid #ccc;
}

::v-deep .audience-list-item--self {
  border-top: 1px solid #ccc;
}

.audience-list-item--add,
.audience-list-item--self {
  position: sticky;
  bottom: 0;
  background: white;

  .v-list-item__title {
    align-items: center;
    display: flex;
    font-size: 14px;

    .add-icon {
      margin-right: 10px;
    }

    .fingerprint-logo {
      width: 24px;
      height: 24px;
    }

    .add-new-audience-header {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: #212121;
    }

    .add-new-audience-subheader {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      display: flex;
      align-items: center;
      letter-spacing: 0.01em;
      color: #7b7b7b;
    }
  }
}
// ::v-deep .v-list-item {
//   width: 45.5vw;
//   overflow: hidden;
//   .v-list-item__title {
//     width: 41vw;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//   }
// }
::v-deep .v-list-item {
  min-height: 40px;
}

::v-deep .v-select.v-text-field input {
  font-size: 14px;
}

.audi-fp-not-complete--auto {
  opacity: 0.5;
}
::v-deep .no-results {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
