<template>
  <div class="tmpDesignerProgressBannerCntnr">
    <div class="banner_content">
      <div class="textContent">
        {{
          $t('templateDesign.banners.progressText', {
            progress: getTdCompletionPercentage,
          })
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'TemplateDesignerProgressBanner',
  data() {
    return {};
  },
  computed: {
    ...mapGetters('users', ['getTdCompletionPercentage']),
    ...mapGetters('replaceImageStore', ['getIsDirty']),
    ...mapGetters('replaceIconStore', ['getIsDirtyIcon']),
    ...mapGetters('slidesStore', [
      'getIsSlideDetailsDirty',
      'getIsSlideACLDirty',
      'getIsSlideBestPractisesDirty',
    ]),
    ...mapState('users', ['currentUser', 'startLoadingSlidesFlag', 'themes']),

    isInWalkthroughPage() {
      return this.$route.path.includes('/help/walkthroughs');
    },

    isBPLSlideView() {
      return this.$route.path.includes('best-practice-library');
    },
  },
  methods: {
    ...mapActions('users', ['setCurrentUser']),
    ...mapActions('slidesStore', [
      'setRouteToNavigate',
      'saveSlidesStateSnapshot',
      'setSlideViewConfirmationModalState',
    ]),
    goToWalkThroughs() {
      this.handleNavigate();
    },
    handleNavigate() {
      if (
        (this.getIsDirty === true ||
          this.getIsDirtyIcon === true ||
          this.getIsSlideDetailsDirty === true ||
          this.getIsSlideACLDirty === true ||
          this.getIsSlideBestPractisesDirty === true) &&
        (this.$route.name === 'slide-detail' || this.isBPLSlideView) &&
        this.startLoadingSlidesFlag &&
        this.themes &&
        this.themes.length > 0
      ) {
        this.setSlideViewConfirmationModalState(true);
        this.setRouteToNavigate({
          content: `/help/walkthroughs`,
          type: 'to_help_walkthroughs',
          action: 'push',
          pushAction: false,
        });
      } else {
        this.saveSlidesStateSnapshot({});
        this.setRouteToNavigate({
          content: '',
          type: '',
          action: '',
          pushAction: false,
        });
        this.$router.push('help/walkthroughs', () => {}).catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tmpDesignerProgressBannerCntnr {
  height: 48px;
  background: #e1f5fb;
  .banner_content {
    font-size: 16px;
    font-weight: 500;
    padding: 14px 8px 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .navCTA {
      padding: 0px 16px;
      .v-btn {
        text-decoration: none;
        letter-spacing: normal;
        text-transform: none;
        background-color: transparent;
        color: #21a7e0;
        box-shadow: none;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
</style>
