<template>
  <div class="tmpDesignerCompleteSelectCntnr">
    <div class="banner_content">
      <div class="textContent">
        {{
          isBPEPage
            ? $t('templateDesign.banners.cravingBPECustomizations')
            : $t('templateDesign.banners.cravingSlideCustomizations')
        }}
      </div>
      <div class="navCTA">
        <v-btn @click="getInTouch" :loading="sendingEmail">{{
          $t('templateDesign.banners.getInTouch')
        }}</v-btn>
      </div>
      <div class="removeBanner">
        <v-progress-circular
          indeterminate
          v-if="removingBanner"
          color="primary"
          size="20"
        />
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="removeBanner()" v-bind="attrs" v-on="on">{{
              'mdi-close'
            }}</v-icon>
          </template>
          <span>{{ $t('templateDesign.banners.dismiss') }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import usersApi from '../../../API/users-api';
import ContactSoonModal from '../ContactSoonModal.vue';
import { sendEmail } from '../../../utils/api-helper';

export default {
  name: 'TemplateDesignerSelectBanner',
  data() {
    return {
      removingBanner: false,
      sendingEmail: false,
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    isBPEPage() {
      // Return false if $route is not yet available
      if (!this.$route) return false;
      const { path } = this.$route;
      // Check if the current route path includes 'best-practice-library'
      return path.includes('best-practice-library');
    },
  },
  methods: {
    ...mapActions('users', ['setCurrentUser']),
    async removeBanner() {
      const userData = {
        performIncrements: {
          showTemplateCravingBanner: 1,
        },
      };

      this.removingBanner = true;
      await usersApi.methods
        .updateUserProfile(userData)
        .then((updatedUser) => {
          if (Object.keys(updatedUser).length > 0) {
            const userInfo = this.currentUser;
            userInfo.user = updatedUser;
            this.setCurrentUser(userInfo);
          }
        })
        .catch((err) => console.log(err));
      this.removingBanner = false;
    },
    async getInTouch() {
      this.sendingEmail = true;
      const params = {
        type: 'template-designer-request',
        meta: {
          firstName: `${this.currentUser?.user?.firstName}`,
          trialDate: `${moment(new Date()).format('MM/DD/YYYY')}`,
        },
      };
      await sendEmail(params)
        .then((response) => {
          console.log(response);
          this.sendingEmail = false;
        })
        .catch((err) => {
          console.log(err);
          this.sendingEmail = false;
        });
      this.$modal.show(
        ContactSoonModal,
        {},
        {
          name: 'ContactSoonModal',
          width: '449px',
          height: '503px',
          styles: {
            borderRadius: '12px',
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.tmpDesignerCompleteSelectCntnr {
  height: 48px;
  background: #e1f5fb;
  .banner_content {
    font-size: 16px;
    font-weight: 500;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .navCTA {
      padding: 0px 16px;
      .v-btn {
        text-decoration: none;
        letter-spacing: normal;
        text-transform: none;
        background-color: transparent;
        color: #21a7e0;
        box-shadow: none;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
</style>
