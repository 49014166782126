<template>
  <div class="bestpractice-gallery-container">
    <div>
      <SearchInputField
        v-if="selectedType === 'slides'"
        :placeholder="$t('build.step3.searchForBestPracticeGuides')"
        :onChange="handleSearchIdeaSlides"
      />
      <SearchInputField
        v-if="selectedType === 'prezentations'"
        :placeholder="$t('prezentationGallery.searchByStoryLine')"
        :onChange="filterPrezentationsWithTextField"
      />
    </div>
    <div>
      <div class="filters-section">
        <div class="filters-action" @click="handleFiltersToggle">
          <span class="filter-title">{{ $t('build.step3.bpgFilters') }}</span>
          <v-icon>
            {{ !isFiltersExpand ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
          </v-icon>
        </div>
        <div :style="{ display: isFiltersExpand ? 'block' : 'none' }">
          <BestPracticesFilters
            ref="slidesFilters"
            :onFilteredSlides="handleFilteredSlides"
            :isloadingCallback="isloadingCallback"
            :onFilterApply="handleFilters"
            :prezentationDetails="prezentationDetails"
            :searchProgress="isLoading"
          />
        </div>
      </div>
      <div v-if="!isFiltersExpand">
        <div class="view-icons" v-if="gallerySlides.length">
          <v-icon
            @click="() => setViewType('grid')"
            :color="viewType === 'grid' ? `#21a7e0` : 'inherit'"
            v-tooltip="{
              content: $t('prezentationGallery.gridView'),
              placement: 'top-center',
            }"
          >
            mdi-view-grid{{ viewType === 'grid' ? '' : '-outline' }}
          </v-icon>
          <v-icon
            @click="() => setViewType('agenda')"
            :color="viewType === 'agenda' ? `#21a7e0` : 'inherit'"
            v-tooltip="{
              content: $t('prezentationGallery.columnView'),
              placement: 'top-center',
            }"
          >
            mdi-view-agenda{{ viewType === 'agenda' ? '' : '-outline' }}
          </v-icon>
        </div>
        <div v-if="!isLoading" :key="componentkey">
          <template v-if="selectedType === 'slides'">
            <template v-if="gallerySlides.length">
              <draggable
                class="slide-drag row slides-container"
                :list="gallerySlides"
                v-bind="dragOptions"
                @end="onSectionDragEnd('Best practice Guides')"
                @add="onSectionDragEnd('Best practice Guides')"
                :group="{ name: 'slides', put: false, pull: 'clone' }"
              >
                <v-col
                  :cols="viewType === 'grid' ? 6 : 12"
                  v-for="(slide, slideIndex) in gallerySlides"
                  :key="`list-${slide.id}-${slideIndex}`"
                  class="image-cell"
                >
                  <SlideThumbnail :slide="slide" :slideIndex="slideIndex">
                    <template>
                      <v-checkbox
                        v-model="selectedSlideObjRef[slide.uniqueKey]"
                        :class="`checkbox ${
                          selectedSlideObjRef[slide.uniqueKey] ? 'checked' : ''
                        }`"
                        size="small"
                        @change="(e) => handleSlidesSelect(e, slide)"
                      />
                    </template>
                  </SlideThumbnail>
                </v-col>
              </draggable>
            </template>
            <v-row v-else>
              <v-col class="nodata">
                <template v-if="!isNetworkError">
                  <div>
                    <img src="/assets/gallery_no_data.svg" alt />
                  </div>

                  <div class="not-found-descriptions">
                    <div>{{ $t('prezentationGallery.noResultText') }}</div>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <img src="/assets/img/sso-user_not_found.svg" alt />
                  </div>

                  <div class="not-found-descriptions">
                    <div>
                      {{ $t('prezentationGallery.unableToFetchSlides') }}
                      <br />{{ $t('prezentationGallery.please') }}
                      <span class="text-btn" @click="handleTryAgain">
                        {{ $t('prezentationGallery.tryAgain') }}
                      </span>
                      !
                    </div>
                  </div>
                </template>
              </v-col>
            </v-row>
          </template>
          <template v-if="selectedType === 'prezentations'">
            <template v-if="filteredPrezentations.length">
              <div @click="handleExpandToggle" class="expand-all-btn">
                {{
                  !isExpanded
                    ? $t('build.step3.expandAllText')
                    : $t('build.step3.collapseAllText')
                }}
                <v-icon id="expand-collapse--auto">
                  {{ !isExpanded ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
                </v-icon>
              </div>
              <v-expansion-panels
                accordion
                multiple
                v-model="expandedPrezentations"
                @change="handleExpandPrezentation"
                class="sections-expand-panel prezentations"
              >
                <v-expansion-panel
                  v-for="(
                    prezentation, prezentationIndex
                  ) in filteredPrezentations"
                  :key="`${prezentationIndex}-${prezentation.id}`"
                  expand
                >
                  <v-expansion-panel-header
                    :class="`prezentations-header prezentations-${prezentationIndex}-header`"
                  >
                    <div>{{ prezentation.name }}</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-expansion-panels
                      accordion
                      multiple
                      v-model="prezSectionExpandList[prezentationIndex]"
                      class="sections-expand-panel sections"
                    >
                      <draggable
                        class="sections-drag"
                        :list="prezentation.sections.list"
                        v-bind="dragOptions"
                        :draggable="`.expansion-panel${
                          prezentationDetails.sections.list.length >= 5
                            ? '-'
                            : ''
                        }`"
                        :group="{ name: 'sections', put: false, pull: 'clone' }"
                        :options="{
                          disabled:
                            prezentationDetails.sections.list.length >= 5,
                        }"
                        @start="onSectionDragStart"
                        @end="
                          onSectionDragEnd('Best practice Guides', 'section')
                        "
                        @add="
                          onSectionDragEnd('Best practice Guides', 'section')
                        "
                      >
                        <v-expansion-panel
                          v-for="(section, sectionIndex) in prezentation
                            .sections.list"
                          :key="`${prezentationIndex}-${prezentation.id}-${sectionIndex}`"
                          expand
                          class="expansion-panel"
                        >
                          <v-expansion-panel-header>
                            <div
                              :class="`chip-title section-header-text prezentations-${prezentationIndex}-section-${sectionIndex}-header-text`"
                            >
                              <div class="drag-icon">
                                <v-icon dense> mdi-dots-vertical </v-icon>
                                <v-icon dense> mdi-dots-vertical </v-icon>
                              </div>
                              <div class="title-text">
                                {{ section.sectionName }}
                              </div>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <draggable
                              class="slide-drag row slides-row"
                              :list="section.slides"
                              v-bind="dragOptions"
                              @end="onSectionDragEnd('Best practice Guides')"
                              @add="onSectionDragEnd('Best practice Guides')"
                              :group="{
                                name: 'slides',
                                put: false,
                                pull: 'clone',
                              }"
                            >
                              <v-col
                                :cols="viewType === 'grid' ? 6 : 12"
                                v-for="(slide, slideIndex) in section.slides"
                                :key="`${viewType}-${slideIndex}-${slide.assetId}`"
                                class="image-cell"
                              >
                                <SlideThumbnail
                                  :slide="slide"
                                  :prezIndex="prezentationIndex"
                                  :sectionIndex="sectionIndex"
                                  :slideIndex="slideIndex"
                                >
                                  <template>
                                    <v-checkbox
                                      v-model="
                                        selectedSlideObjRef[slide.uniqueKey]
                                      "
                                      :class="`checkbox ${
                                        selectedSlideObjRef[slide.uniqueKey]
                                          ? 'checked'
                                          : ''
                                      }`"
                                      size="small"
                                      @change="
                                        (e) =>
                                          handleSlidesSelect(e, slide, true)
                                      "
                                    />
                                  </template>
                                </SlideThumbnail>
                              </v-col>
                            </draggable>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </draggable>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
            <v-row v-else>
              <v-col class="nodata">
                <template v-if="!isNetworkError">
                  <div>
                    <img src="/assets/gallery_no_data.svg" alt />
                  </div>

                  <div class="not-found-descriptions">
                    <div>{{ $t('prezentationGallery.noResultText') }}</div>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <img src="/assets/img/sso-user_not_found.svg" alt />
                  </div>

                  <div class="not-found-descriptions">
                    <div>
                      {{ $t('prezentationGallery.unableToFetchSlides') }}
                      <br />{{ $t('prezentationGallery.please') }}
                      <span class="text-btn" @click="handleTryAgain">
                        {{ $t('prezentationGallery.tryAgain') }}
                      </span>
                      !
                    </div>
                  </div>
                </template>
              </v-col>
            </v-row>
          </template>
        </div>
        <v-row align="center" justify="center" class="slides-container" v-else>
          <clip-loader
            :loading="isLoading"
            :color="`#21a7e0`"
            :width="20"
            :height="20"
          />
        </v-row>
      </div>
    </div>
    <v-snackbar
      color="#ffffff"
      v-model="selectedSlides.length"
      bottom
      right
      :timeout="-1"
    >
      <span style="color: rgba(0, 0, 0, 0.87)">
        {{
          $t('prezentationGallery.selectedSlidesText', {
            selectedSlides: selectedSlides.length,
          })
        }}
      </span>
      <span>
        <v-menu
          offset-y
          v-if="
            !isIndexToAddSlidesSelected &&
            prezentationDetails.sections.list.length
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="add-prezentation-btn"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t('prezentationGallery.addToPrezentationBtn') }}
              <v-icon end>mdi-menu-up</v-icon>
            </v-btn>
          </template>
          <v-list class="menu-items-wrapper">
            <v-list-item
              v-for="(item, index) in slidesList"
              :key="index"
              @click="() => handleSlideToAddClick(item)"
            >
              <v-list-item-title>
                {{ item.sectionName || item.count }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          class="add-prezentation-btn"
          color="primary"
          v-else
          @click="() => handleSlideToAddClick()"
        >
          {{ $t('prezentationGallery.addToPrezentationBtn') }}
        </v-btn>
      </span>
      <v-icon
        size="14px"
        v-show="selectedSlides.length"
        @click="handleSelectionClear"
        color="rgba(0,0,0,0.87)"
      >
        mdi-close
      </v-icon>
    </v-snackbar>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import draggable from 'vuedraggable';
import { snakeCase, debounce } from 'lodash';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { getFileURL } from '@/utils/calyrex';
import SearchInputField from './SearchInputField.vue';
import SlideThumbnail from './SlideThumbnail.vue';
import { convertPdfToImage } from '../../../utils/pdf-to-png';
import { getThumbnail } from '../../../store/modules/helper';
// import { AnalyticsHandler } from '../Analytics/AnalyticsHandler';
import { performSearch } from '../../../graphql/queries';
import BestPracticesFilters from './BestPracticesFilters.vue';
import {
  getBestPracticesExamplesDecks,
  handleKnowSearchBySort,
  getUserCompanyInfo,
} from '../../../utils/api-helper';
import { GraphQLService } from '../../../services/GraphQLService';
import { trackPrezentationEvents } from '../Analytics/PrezentationEvents';
import {
  BUILD,
  getBuildPrezentationData,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_PLACEMENT,
  TD_QUERY,
} from '../Analytics/MatomoTrackingDataHelper';
import { trackBuildEvents } from '../Analytics/BuildEvents';

export default {
  components: {
    SearchInputField,
    SlideThumbnail,
    ClipLoader,
    BestPracticesFilters,
    draggable,
  },
  data() {
    return {
      viewType: 'grid',
      selectedType: 'slides',
      selectedSlides: [],
      gallerySlides: [],
      filteredPrezentations: [],
      selectedSlideObjRef: {},
      isLoading: true,
      searchText: '',
      isNetworkError: false,
      isFiltersExpand: false,
      allPrezentations: [],
      expandedPrezentations: [0],
      prezSectionExpandList: [[0, 1, 2, 3, 4]],
      isExpanded: false,
      companyTheme: '',
      filterText: '',
      componentkey: 1,
    };
  },
  props: {
    slidesList: {
      type: Array,
      default: () => [],
    },
    onAddSlidesToSlideNumber: {
      type: Function,
      default: () => {},
    },
    prezentationDetails: {
      type: Object,
      default: () => ({}),
    },
    isIndexToAddSlidesSelected: {
      type: Boolean,
      default: false,
    },
    origin: {
      type: String,
      default: '',
    },
    currentTab: {
      type: String,
      default: '',
    },
    onSectionDragStart: {
      type: Function,
      default: () => {},
    },
    onSectionDragEnd: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    isIndexToAddSlidesSelected() {
      const list = this.selectedSlides;
      this.selectedSlides = [...list];
    },
    currentTab() {
      if (this.currentTab !== 'best-practice') {
        this.handleSelectionClear();
      }
    },
    filterText(val) {
      if (this.origin.toLowerCase() === BUILD) {
        trackBuildEvents.buildGallerySearchQuery(this.currentUser, {
          ...getBuildPrezentationData(this.prezentationDetails),
          [TD_PLACEMENT]: 'Best Practice Library',
          [TD_QUERY]: val,
        });
      } else {
        trackPrezentationEvents.prezentationsSlideSearchQuery(
          this.currentUser,
          this.prezentationDetails,
          {
            [TD_QUERY]: val,
            [TD_PLACEMENT]: 'Best Practice Library',
          },
        );
      }
    },
  },
  computed: {
    ...mapState('users', ['currentUser', 'currentTheme']),

    ...mapState('users', [
      'currentUser',
      'themes',
      'startLoadingSlidesFlag',
      'companyData',
    ]),
    ...mapState('prezentations', [
      // 'allPrezentations',
      'filteredAndSearchedPrezentations',
      'reloadOnLastPrezDelete',
      'snapshot',
      'prezentationBackClicked',
    ]),
    ...mapGetters('bestPracticesExamples', [
      'getAllDecks',
      'getSelectedViewType',
    ]),
    dragOptions() {
      return {
        animation: 900,
        ghostClass: 'ghost-list',
        disabled: false,
      };
    },
    currentThemeCode() {
      return (
        this.currentTheme?.code || this.currentUser?.theme?.code || 'default'
      );
    },
  },
  async mounted() {
    try {
      let companyName = '';
      if (this.currentUser?.company?.name) {
        companyName = this.currentUser.company.name.toLowerCase();
      }
      const { data } = await getUserCompanyInfo(companyName).catch((err) => {
        console.log(err);
        this.companyTheme = this.currentThemeCode || '';
      });
      this.companyTheme = snakeCase(Object.keys(data)[0]);
    } catch (e) {
      console.log(e);
    }
    this.searchWithinCategory();
  },
  methods: {
    ...mapActions('prezentations', [
      'setUserSelectedPrezentationView',
      'setFilterAndSearchedPrezentations',
      'setReloadLastPrezOnDelete',
      'setPrezentationStateSnapshot',
      'setPrezentationViewOrigin',
    ]),
    ...mapActions('bestPracticesExamples', [
      'setDecksOnLoad',
      'setDecksLoaded',
    ]),

    handleExpandPrezentation(e) {
      this.expandedPrezentations = [...e];
      const lastIndex = e.pop();
      if (!this.prezSectionExpandList[lastIndex]) {
        this.prezSectionExpandList[lastIndex] = [0, 1, 2, 3, 4];
      }
    },
    handleExpandToggle() {
      this.isExpanded = !this.isExpanded;
      if (this.isExpanded) {
        this.expandedPrezentations = [];
        this.filteredPrezentations.forEach((section, sectionIndex) => {
          this.expandedPrezentations.push(sectionIndex);
        });
      } else {
        this.expandedPrezentations = [];
      }
    },
    async loadPrezentationsByOwner() {
      this.isLoading = true;

      this.expandedPrezentations = [0];
      this.prezSectionExpandList = [[0, 1, 2, 3, 4]];
      await getBestPracticesExamplesDecks()
        .then((resp) => {
          if (
            resp &&
            resp.data &&
            resp.data.items &&
            resp.data.items.length > 0
          ) {
            const list = [...resp.data.items];

            list.forEach((prez, i) => {
              prez.sections?.list?.forEach((section, j) => {
                section.slides?.forEach((slide, s) => {
                  list[i].sections.list[j].slides[s] = {
                    ...slide,
                    uniqueKey: `${
                      slide.assetId || slide.landscape
                    }_${i}_${j}_${s}`,
                    assetId: slide.assetId || slide.landscape,
                    isAddedFromBestpracticeDecks: true,
                  };
                });
              });
            });
            this.setDecksOnLoad([...list]);
          } else {
            this.setDecksOnLoad([]);
          }
        })
        .catch((err) => {
          this.setDecksOnLoad([]);
          console.log(err);
        });
      this.allPrezentations = this.getAllDecks;

      this.filteredPrezentations = JSON.parse(
        JSON.stringify(this.allPrezentations.slice(0, 24)),
      );

      this.isLoading = false;
    },
    formatPresentations(presentations) {
      for (let i = 0; i < presentations.length; i++) {
        presentations[i].sharedWith = presentations[i].shared
          ? 'Shared'
          : 'Private';
      }
      return presentations;
    },
    handleSelectionClear() {
      this.selectedSlides = [];
      this.selectedSlideObjRef = {};
    },
    handleTryAgain() {
      this.gallerySlides = [];
      this.searchWithinCategory(this.searchText);
      this.isFiltersExpand = false;
    },
    // eslint-disable-next-line func-names
    handleSearchIdeaSlides: debounce(function (e) {
      if (e) {
        if (this.searchText !== e) {
          const category = e?.toLowerCase()?.replaceAll(' ', '_');
          this.searchWithinCategory(category || '');

          this.searchText = e;
        }
        this.filterText = e;
      } else {
        this.searchWithinCategory();
      }
    }, 300),
    // eslint-disable-next-line func-names
    filterPrezentationsWithTextField: debounce(function (e) {
      let prezs = [];
      if (e) {
        this.filterText = e;
        prezs = [...this.getAllDecks].filter(
          (przs) =>
            przs.name.toLowerCase().includes(e.toLowerCase()) ||
            przs.type?.toLowerCase().includes(e.toLowerCase()) ||
            przs.author?.toLowerCase().includes(e.toLowerCase()),
        );
        this.filteredPrezentations = JSON.parse(
          JSON.stringify(prezs.slice(0, 24)),
        );
      } else {
        this.filteredPrezentations = JSON.parse(
          JSON.stringify(this.getAllDecks.slice(0, 24)),
        );
      }
      this.componentkey += 1;
    }, 500),
    async handleFilters(type, filterType) {
      this.selectedType = type;
      this.isFiltersExpand = false;
      if (type === 'slides') {
        if (filterType.isFavorite || filterType.isDownloaded) {
          let filterTypeText = 'favorites';
          if (filterType.isFavorite && filterType.isDownloaded) {
            filterTypeText = 'all';
          } else if (filterType.isDownloaded) {
            filterTypeText = 'downloaded';
          }
          this.searchDownloadandFavourite(filterTypeText);
        } else {
          this.searchWithinCategory();
        }
      } else if (type === 'prezentations') {
        await this.loadPrezentationsByOwner();
        if (filterType.isFavorite || filterType.isDownloaded) {
          this.allPrezentations = this.getAllDecks;
          let prezs = [];
          if (filterType.isDownloaded && filterType.isFavorite) {
            prezs = this.allPrezentations.filter(
              (prz) => prz.isDownloaded && prz.isFavorite,
            );
          } else if (filterType.isDownloaded) {
            prezs = this.allPrezentations.filter((prz) => prz.isDownloaded);
          } else if (filterType.isFavorite) {
            prezs = [...this.allPrezentations.filter((prz) => prz.isFavorite)];
          }
          this.filteredPrezentations = JSON.parse(
            JSON.stringify(prezs.slice(0, 24)),
          );
        }
      }
    },
    searchDownloadandFavourite(queryType) {
      this.isLoading = true;
      this.isNetworkError = false;
      handleKnowSearchBySort({
        query_type: queryType,
        theme_id: this.currentThemeCode,
      })
        .then((res) => {
          this.gallerySlides =
            res?.data?.payloads.map((item) => ({
              ...item,
              uniqueKey: item.assetId || item.landscape,
              assetId: item.assetId || item.landscape,
              outline: item.title,
              category: item?.categories ? item?.categories[0] : '',
              filename: item.filename,
              uniqueID: item.unique_id,
              isAdded: true,
            })) || [];
        })
        .catch((err) => {
          console.log(err);
          this.isNetworkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    searchWithinCategory(category) {
      this.isLoading = true;
      this.isNetworkError = false;
      this.gallerySlides = [];
      const params = {
        andprefs: {
          theme: this.companyTheme,
          is_know: 'yes',
        },
        // category: [],
        query: category || '',
        choice: {
          data: 4.61,
          visual: 5.97,
        },
        limit: 24,
        morprefs: {
          source: [],
        },
        notprefs: {
          category: [
            'nature',
            'mountain',
            '80s',
            'sports',
            '4th_of_july',
            'thanksgiving',
            'christmas',
            'sea',
            'movie',
            'space',
            'race_car',
            'prezfingerprinttest',
          ],
          source: [],
        },
        onlyids: false,
        orderby: 'CHOICE_DESC',
        orprefs: [],
        skip: 0,
        username: this.currentUser.user.cognitoID,
      };

      const serialized = JSON.stringify(params);
      // Leaving this to log an empty search
      // AnalyticsHandler.performSlideSearch(this.currentUser, category);
      // AnalyticsHandler.logUserStats(this.currentUser);
      GraphQLService.requestWithToken(
        graphqlOperation(performSearch, {
          parameters: serialized,
        }),
      )
        .then((res) => {
          const result = JSON.parse(res.data.performSearch);
          if (result.statusCode === 200) {
            const slidesList = [];
            result.body.payloads?.forEach((item) => {
              if (
                item.keytype === 'K_SLIDEDATA' &&
                item.prefs.is_know === 'yes'
              ) {
                slidesList.push({
                  ...item,
                  uniqueKey: item.assetId || item.landscape,
                  assetId: item.assetId || item.landscape,
                  name: item.display_name,
                  type: 'Slides',
                  category: item.name,
                });
              }
            });
            this.gallerySlides = slidesList;
            this.componentkey += 1;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isNetworkError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setViewType(type) {
      this.viewType = type;
      if (this.origin.toLowerCase() === BUILD) {
        trackBuildEvents.buildGalleryViewClick(this.currentUser, {
          ...getBuildPrezentationData(this.prezentationDetails),
          [TD_QUERY]: this.searchText,
          [TD_PLACEMENT]: 'Best Practice Library',
          [TD_COMMON_COLUMN_NAME]: 'view selected',
          [TD_COMMON_COLUMN_VALUE]:
            this.viewType === 'grid' ? 'Row view' : 'Column view',
        });
      } else {
        trackPrezentationEvents.prezentationsGalleryViewClick(
          this.currentUser,
          this.prezentationDetails,
          {
            [TD_QUERY]: this.searchText,
            [TD_PLACEMENT]: 'Best Practice Library',
            [TD_COMMON_COLUMN_NAME]: 'view selected',
            [TD_COMMON_COLUMN_VALUE]:
              this.viewType === 'grid' ? 'Row view' : 'Column view',
          },
        );
      }
    },

    handleFiltersToggle() {
      this.isFiltersExpand = !this.isFiltersExpand;
      if (!this.isFiltersExpand) {
        this.$refs.slidesFilters.resetToDefaultIfApplyNotClicked();
      }
    },
    isloadingCallback(val) {
      this.isLoading = val;
    },
    handleFilteredSlides(slidesList) {
      this.isFiltersExpand = false;
      this.searchText = '';
      this.gallerySlides = slidesList.map((slide) => ({
        ...slide,
        assetId: slide.assetId || slide.landscape,
        outline: slide.title,
        category: slide?.categories ? slide?.categories[0] : '',
        filename: slide.filename,
        uniqueID: slide.unique_id,
        isAdded: true,
      }));
    },
    async handleSlidesSelect(e, slide, isDeck) {
      if (e) {
        let obj = {
          ...slide,
          assetId: slide.assetId || slide.landscape,
          outline: slide.title,
          category: slide?.categories ? slide?.categories[0] : '',
          filename: slide.filename,
          uniqueID: slide.unique_id,
          thumbnail: slide.thumbnail || '',
          isAdded: true,
        };

        if (isDeck) {
          obj = { ...slide, isAdded: true };
        }

        // Setting slide intermedietly to avoid displaying image caching
        this.slideData = obj;
        if (obj.assetId) {
          const thumbnailPath = obj.assetId;
          if (slide?.prefs?.source === 'uploaded') {
            const tempThumbnail = await getFileURL(
              this.currentUser.user.cognitoID,
              thumbnailPath,
              this.currentUser.userIp,
              'IOCUpload',
            );
            // obj.isUserUploaded = true;
            obj.thumbnail = await convertPdfToImage(tempThumbnail);
          } else if (slide?.prefs?.source === 'generated') {
            if (
              thumbnailPath.startsWith('/newImageReplaceOutput/output/') ||
              thumbnailPath.startsWith('/output/pptx/')
            ) {
              obj.thumbnail = await getThumbnail(thumbnailPath);
            } else {
              const tempThumbnail = await getFileURL(
                this.currentUser.user.cognitoID,
                thumbnailPath,
                this.currentUser.userIp,
                'IOCUpload',
              );
              obj.thumbnail = tempThumbnail;
            }
          } else {
            let type =
              this.prezentationDetails.prezentationSource === 'Generated'
                ? 'IOCUpload'
                : 'Product';
            if (obj.isOPUploaded) {
              type = 'OP';
            } else if (
              obj.isGenerated ||
              obj.source === 'generated' ||
              obj.isIOCUploaded
            ) {
              type = 'IOCUpload';
            }
            if (type !== 'OP') {
              obj.thumbnail = await getFileURL(
                this.currentUser.user.cognitoID,
                thumbnailPath,
                this.currentUser.userIp,
                type,
              );
            }
          }
        }
        this.selectedSlides.push(obj);
      } else {
        this.selectedSlides = this.selectedSlides.filter(
          (s) => s.uniqueKey !== slide.uniqueKey,
        );
      }
      this.selectedSlideObjRef = {};
      this.selectedSlides.forEach((s) => {
        this.selectedSlideObjRef[s.uniqueKey] = true;
      });
    },
    handleSlideToAddClick(obj) {
      this.onAddSlidesToSlideNumber(obj, this.selectedSlides);
      this.selectedSlides = [];
      this.selectedSlideObjRef = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.bestpractice-gallery-container {
  .view-icons {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;

    .v-icon {
      margin-left: 20px;
    }
  }

  .slides-container {
    min-height: 200px;
    // max-height: 400px;
    // overflow: auto;
    .v-image {
      box-shadow: -1px -1px 4px 0px #0000001a;
      box-shadow: 1px 1px 4px 0px #0000001a;
      position: relative;
    }

    .checkbox {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      margin: 8px;
      padding: 0;
    }

    .checked {
      display: block;
    }

    .image-cell:hover {
      cursor: move;
      .checkbox {
        display: block;
      }
    }
  }

  .nodata {
    margin: 20px;
    text-align: center;

    img {
      max-width: 200px;
    }

    .text-btn {
      cursor: pointer;
      color: #21a7e0;
    }

    .not-found-descriptions {
      margin: 20px;
      font-family: Lato;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
    }
  }

  .filters-section {
    margin: 15px 0;
    cursor: pointer;
    .filters-action {
      display: flex;
      justify-content: space-between;
      color: #21a7e0;

      .v-icon {
        color: #21a7e0;
      }
    }
  }

  .add-prezentation-btn {
    margin-top: -3px;
    letter-spacing: normal;
    box-shadow: none !important;
    font-size: 14px;
    height: 37px !important;
    color: #21a7e0;
    line-height: 2rem;
    margin-left: 5px;
    margin-bottom: 2px;
    text-transform: none !important;
    background-color: transparent !important;
  }

  .sections-drag {
    width: 100%;

    .v-expansion-panel-header {
      padding: 0px;
    }
  }

  .sections-expand-panel {
    margin: 0;
    width: unset;

    &.sections {
      margin: 0 24px;
    }

    .prezentations-header {
      padding-left: 10px;
      padding-right: 10px;
    }

    ::v-deep .v-expansion-panel::before {
      box-shadow: none !important;
    }

    .slides-row {
      padding: 10px;
    }
  }

  .section-header-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
    display: flex;
    align-items: center;
  }
  .drag-icon {
    display: flex !important;
    cursor: move;
  }

  .chip-title {
    display: flex;
    align-items: center;
    width: 100% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    .v-icon:nth-child(2) {
      margin-left: -15px;
    }

    .v-icon {
      color: #a2a9b0;
    }

    .title-text {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: black;
      padding: 0px;
      white-space: normal;
      font-size: 16px;
    }
    .title-text::after {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0 5px;
      background: white;
    }
  }

  .prezentations {
    min-height: 200px;
    // overflow: auto;
    .v-image {
      box-shadow: -1px -1px 4px 0px #0000001a;
      box-shadow: 1px 1px 4px 0px #0000001a;
      position: relative;
    }

    .checkbox {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      margin: 8px;
      padding: 0;
    }

    .checkbox.checked {
      display: block;
    }

    .image-cell:hover {
      cursor: move;
      .checkbox {
        display: block;
      }
    }
  }

  .expand-all-btn {
    cursor: pointer;
    color: #21a7e0;
    text-align: end;
    padding: 0 10px;

    .v-icon {
      color: #21a7e0;
    }
  }
}

.ghost-list {
  .v-icon {
    color: #a2a9b0;
    display: none;
  }

  .checkbox {
    display: none;
  }

  ::v-deep .v-expansion-panel-header__icon {
    display: none;
  }
}
.menu-items-wrapper {
  max-height: 400px;
  overflow: auto;
  padding-bottom: 60px;
}
</style>
