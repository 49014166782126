<template>
  <div class="submit-payment-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div v-if="loadingCards" class="spinner">
      <v-progress-circular :size="50" indeterminate color="#21a7e0" />
    </div>
    <div class="contentContainer" v-else>
      <div class="submit-payment-wrapper-title">Submit Payment</div>
      <div class="submit-payment-content-wrapper">
        <div class="submit-payment-info">
          <div class="submit-payment-info-title">Payment information:</div>
          <!---->
          <div
            v-if="savedCardsExists && !addingNewPaymentMethod"
            class="paymentMethodsCardsContainer"
          >
            <div
              v-if="selectedSavedCard && viewMode === 'cardSelected'"
              class="selectedCardMode"
            >
              <div class="selectedCardModeHeading">
                {{
                  `Pay with ${
                    selectedSavedCard.is_default ? 'default' : 'selected'
                  } card`
                }}
              </div>
              <div class="selectedCardInfoAndLayout">
                <PaymentMethodCard
                  :card="selectedSavedCard"
                  :actionsNeeded="false"
                />
              </div>
            </div>
            <div class="paymentCardsContainer" v-else>
              <div class="paymentCardsContainerHeading">
                {{ 'Select a card on file or add a new payment method' }}
              </div>
              <div class="paymentCardsContainerList">
                <div
                  v-for="(card, ind) in [...getPaymentMethods].slice(
                    0,
                    getPaymentMethods.length - 1,
                  )"
                  class="paymentCardsListItems"
                  :key="ind"
                  @click="
                    handleSelectAvailableCardsForPayment(card, 'existingCard')
                  "
                >
                  <PaymentMethodCard
                    :card="card"
                    :key="ind"
                    :actionsNeeded="false"
                  />
                </div>
                <div
                  class="addNewPaymentCard"
                  @click="handleSelectAvailableCardsForPayment(null, 'addNew')"
                >
                  <v-icon large color="#21a7e0">{{ 'mdi-plus' }}</v-icon>
                  <div class="cardTitle">{{ 'Add new payment method' }}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!savedCardsExists || addingNewPaymentMethod === true"
            class="noCardsPresent"
          >
            <div class="new-card-addition-title">
              {{ 'New payment method' }}
            </div>
            <div class="stripeCardAndName">
              <div class="credit-card-wrapper">
                <div class="credit-card-title">Credit or debit card number</div>
                <stripe-element-card
                  @element-change="stripeElementCardChanged($event)"
                  ref="stripeElementCard"
                  :pk="striprPublicKey"
                />
              </div>
              <div class="names-wrapper">
                <div class="name-wrapper">
                  <div class="name-wrapper-title">Name on card</div>
                  <input
                    v-model="cardHolderName"
                    placeholder="Card holder's name"
                  />
                  <div class="nameError" v-if="cardHolderName.length > 30">
                    {{ 'Name cannot exceed 30 characters' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!---->
          <div
            class="billing-address-wrapper"
            v-if="
              !savedCardsExists ||
              addingNewPaymentMethod === true ||
              viewMode === 'cardSelected'
            "
          >
            <div class="billing-address-title">Billing address</div>
            <input
              v-model="billingAddress"
              placeholder="Enter your billing address"
            />
          </div>
          <div
            class="state-country-wrapper"
            v-if="
              !savedCardsExists ||
              addingNewPaymentMethod === true ||
              viewMode === 'cardSelected'
            "
          >
            <div class="country-wrapper">
              <div class="country-wrapper-title">Country</div>
              <Country
                :countryChanged="countryChanged"
                :countrySelected="country"
              />
            </div>
            <div class="state-wrapper">
              <div class="state-wrapper-title">{{ stateLabel }}</div>
              <State
                :stateChanged="stateChanged"
                :country="country"
                :disabled="country === ''"
                :selectedState="state_code"
              />
              <div
                class="stateError"
                v-if="(country === 'US' || country === 'CA') && stateError"
              >
                {{ 'Please select a state' }}
              </div>
            </div>
          </div>
          <div
            class="zip-wrapper"
            v-if="
              !savedCardsExists ||
              addingNewPaymentMethod === true ||
              viewMode === 'cardSelected'
            "
          >
            <div class="zip-code-wrapper">
              <div class="zip-code-wrapper-title">Zip code</div>
              <input
                v-model="zipCode"
                placeholder="12345"
                :disabled="country === ''"
                :maxlength="getZipCodeMaxLength()"
                @input="isZipValidAsPerCountry"
              />
              <div
                class="zipError"
                v-if="(country === 'US' || country === 'CA') && zipCodeError"
              >
                {{ 'Please enter a valid zip code' }}
              </div>
            </div>
          </div>
          <PoweredByStripe
            v-if="
              !savedCardsExists ||
              addingNewPaymentMethod === true ||
              viewMode === 'cardSelected'
            "
          />
          <div
            class="switchLayouts"
            v-if="
              savedCardsExists &&
              (viewMode === 'cardSelected' || addingNewPaymentMethod === true)
            "
          >
            <v-btn @click="handleSwitchPaymentLayout">
              {{ 'Select a different card' }}
            </v-btn>
          </div>
        </div>
        <div class="your-order-info">
          <div class="your-order-info-title">Your order:</div>
          <div class="your-order-info-content">
            <div class="your-order-info-title">
              {{ plan.name }}
            </div>
            <div class="your-order-info-content-image">
              <img :src="`/assets/img/profile/${plan.image}`" alt />
            </div>
            <div class="your-order-info-content-title">
              {{ planPrice }}
            </div>
            <div class="your-order-info-content-sub-title">
              {{ priceSubText }}
            </div>

            <div
              v-if="
                getHeader(plan, currentActiveSubscription) &&
                getHeader(plan, currentActiveSubscription) !== ''
              "
              class="subscription-wrapper-header"
            >
              {{ getHeader(plan, currentActiveSubscription) }}
            </div>
            <div
              class="subscription-wrapper-detail"
              v-for="(item, index) in getPlanDescription(plan.Detail)"
              :key="index"
            >
              <div class="detailItem">
                <div class="tickContainer">
                  <img
                    src="/assets/img/profile/check-mark.svg"
                    alt="Tick Mark"
                    class="tick-mark"
                  />
                </div>
                <div class="detailText">{{ item }}</div>
              </div>
            </div>
            <div class="your-oder-info-content-subscript-title">
              Subscription term:
            </div>
            <div class="your-oder-info-content-subscript-value">
              {{ getStartDate() }} to {{ getEndDate() }}
            </div>
            <div class="your-oder-info-content-subscript-input-title">
              Licenses included with your subscription:
            </div>
            <div class="your-oder-info-content-subscript-input-value">
              <v-text-field
                v-model.number="numSeats"
                :disabled="isRenewal"
                single-line
                type="number"
                min="1"
                :rules="numberRules"
                class="centered-input shrink"
                style="width: 50px"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="total-prices-info">
        <div class="taxesCalculateError" v-if="taxJarError">
          {{ 'Could not calculate taxes.' }}
        </div>
        <div class="total-prices-info-sub" v-else>
          Subtotal: US ${{ `${formatAmountWithCommas(getTotalPrice())}` }}
        </div>
        <div class="validZipCodeRequest" v-if="taxJarError">
          {{ 'Please enter a valid zip code' }}
        </div>
        <div class="total-prices-info-tax" v-else>
          {{ `Tax: ${taxToBeApplied}%` }}
        </div>
        <div class="total-prices-info-total-and-save-card">
          <div class="saveCheckbox">
            <v-checkbox
              v-model="saveCardDetailsOrAutoRenew"
              :label="`${
                !savedCardsExists ||
                addingNewPaymentMethod ||
                !(selectedSavedCard && selectedSavedCard.is_default)
                  ? 'Set card as default and enable auto-renew'
                  : 'Enable auto-renew'
              }`"
              color="#20a7e0"
              hide-details
              @click="handleAutoRenewClick"
            />
          </div>
          <div class="total-prices-info-total" v-if="!taxJarError">
            Total: US ${{ `${formatAmountWithCommas(getFinalPrice())}` }}
          </div>
        </div>
      </div>
      <!--
      <span class="submit-payment-terms-privacy" v-if="!isRenewal">
        {{
          `By clicking below, you agree that you will be charged US $${formatAmountWithCommas(
            getFinalPrice(),
          )} (includes ${taxToBeApplied}%
        tax) once. At the end of your one-year term, your
        subscription will expire until you renew.`
        }}
        Reach us at
        <a href="mailto:success@prezent.ai">success@prezent.ai</a> to cancel
        anytime. You also agree to our
        <a href="https://prezent.ai/terms-of-service/" target="_blank"
          >Terms of Service</a
        >
        and
        <a href="https://prezent.ai/privacy-policy/" target="_blank"
          >Privacy Policy</a
        >.
      </span>
      !-->
      <span class="submit-payment-terms-privacy">
        {{
          `By clicking below, you agree that you will be charged US $${formatAmountWithCommas(
            getFinalPrice(),
          )} (includes ${taxToBeApplied}%
        tax) once. At the end of your one-year term, your
        subscription will ${
          saveCardDetailsOrAutoRenew
            ? `automatically renew for another one-year term until you
        cancel (price subject to change)`
            : 'expire until you renew'
        }. ${
            saveCardDetailsOrAutoRenew &&
            (!savedCardsExists || addingNewPaymentMethod)
              ? `Your card details will be saved for any
        future charges.`
              : ''
          }`
        }}
        Reach us at
        <a href="mailto:success@prezent.ai">success@prezent.ai</a> to cancel
        anytime. You also agree to our
        <a href="https://prezent.ai/terms-of-service/" target="_blank"
          >Terms of Service</a
        >
        and
        <a href="https://prezent.ai/privacy-policy/" target="_blank"
          >Privacy Policy</a
        >.
      </span>
      <div class="submit-payment-actions">
        <v-btn
          color="#21a7e0"
          rounded
          class="agree-subscribe"
          width="298"
          height="50"
          @click="handleSubscribe"
          :loading="getUpgradeProgress"
          :disabled="handleAgreeDisable()"
        >
          {{ isRenewal ? 'Agree and Renew' : 'Agree and Subscribe' }}
        </v-btn>
        <div class="submit-payment-actions-right">
          <div class="submit-payment-actions-right-title">
            Have questions for us?
          </div>
          <div class="submit-payment-actions-right-link">
            <a href="#">
              <div @click="getInTouch()">Get in touch</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import { mapState, mapGetters, mapActions } from 'vuex';
import { Auth } from 'aws-amplify';
// import CustomRegistrationApi from '../../API/custom-registration-api';
// import API2 from '../../utils/api';
import PaymentApi, {
  getBillingAddress,
  getSavedPaymentMethods,
  getTaxRate,
} from '../../API/payment-api';
import SuccessPaymentDetail from './SuccessPaymentDetail.vue';
import FailedPaymentDetail from './FailedPaymentDetail.vue';
import ContactSoonModal from './ContactSoonModal.vue';
import PoweredByStripe from './PoweredByStripe.vue';
import Country from './Country.vue';
import State from './State.vue';
import ProcessingPaymentDetail from './ProcessingPaymentDetail.vue';
import { sendEmail } from '../../utils/api-helper';
import ECommerceActionsApi from '../../API/ecommerce-actions-api';
import PaymentMethodCard from '../Main/Profile/payment-methods/PaymentMethodCard.vue';
import { trackProfileEvents } from './Analytics/ProfileEvents';
import {
  TD_PLACEMENT,
  TD_RENEW,
  TD_UPGRADE,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_CLICK_STATUS,
  TD_CHECKED,
  TD_UNCHECKED,
  TD_TEAM,
} from './Analytics/MatomoTrackingDataHelper';

const dateFormat = require('dateformat');

export default {
  name: 'SubmitPaymentDetail',
  components: {
    StripeElementCard,
    PoweredByStripe,
    Country,
    State,
    PaymentMethodCard,
  },
  props: {
    upgradePlan: {
      type: Function,
      default: () => ({}),
    },
    plan: {
      type: Object,
    },
    subscriptionID: {
      type: String,
      required: true,
    },
    licenses: {
      type: Number,
      default: 1,
    },
    isRenewal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      numberRules: [
        (v) => String(v).length > 0 || 'This field may not be empty',
        (v) => Number.isInteger(v) || 'The value must be an integer number',
        (v) => v > 0 || 'The value must be greater than zero',
      ],
      minValue: 1,
      paymentMethod: null,
      cardHolderName: '',
      licenseNum: '',
      billingAddress: '',
      zipCode: '',
      country: 'US',
      state: '',
      state_code: '',
      processing: false,
      upgradeSuccess: false,
      paymentFailureMsg: '',
      isCardElementError: true,
      totalPrice: 0,
      striprPublicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
      numSeats: 1,
      saveCardDetailsOrAutoRenew: true,
      selectedSavedCard: null,
      loadingCards: false,
      viewMode: '',
      addingNewPaymentMethod: false,
      fetchedBillingAddress: '',
      fetchedZipCode: '',
      fetchedCountry: 'US',
      fetchedStateCode: '',
      fetchedState: '',
      taxToBeApplied: 0,
      taxForCalculation: 0,
      taxJarError: false,
      stateError: false,
      zipCodeError: false,
      fetchingTaxRates: false,
      pricingSlabsList: [],
    };
  },
  computed: {
    ...mapState('users', [
      'currentUser',
      'currentActiveSubscription',
      'availablePlans',
    ]),
    ...mapGetters('users', ['getUpgradeProgress']),
    ...mapGetters('paymentMethods', ['getPaymentMethods']),
    planPrice() {
      if (this.plan && ['12347', '12348', '12349'].includes(this.plan.id)) {
        return `$${this.getBasePrice()} (plus taxes)`;
      }
      return `${this.getBasePrice()}`;
    },
    priceSubText() {
      if (this.plan && ['12347', '12348', '12349'].includes(this.plan.id)) {
        return 'per user per year';
      }
      return 'per user per year';
    },
    isTrial() {
      return this.currentUser.user.role.toLowerCase() === 'trialuser';
    },
    savedCardsExists() {
      return this.getPaymentMethods.length >= 2;
    },
    isExistingBillingAddress() {
      return (
        this.billingAddress === this.fetchedBillingAddress &&
        this.zipCode === this.fetchedZipCode &&
        this.country === this.fetchedCountry &&
        this.state_code === this.fetchedStateCode &&
        this.state === this.fetchedState
      );
    },
    stateLabel() {
      return this.country === 'US' || this.country === 'CA'
        ? 'State'
        : 'State (optional)';
    },
  },

  async beforeMount() {
    this.loadingCards = true;
    await PaymentApi.methods
      .getPaymentMethods()
      .then((data) => {
        if (data && data.length) {
          if (data.length <= 1) {
            const onlyCard = data[0];
            this.selectedSavedCard = onlyCard;
          } else {
            const defaultCard = data.find((card) => card.is_default);
            this.selectedSavedCard = !defaultCard ? data[0] : defaultCard;
          }
          this.viewMode = 'cardSelected';
          this.addingNewPaymentMethod = false;
          this.setPaymentMethods(data);
        } else {
          this.addingNewPaymentMethod = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    await getBillingAddress()
      .then(async (resp) => {
        if (resp?.data && resp?.data?.id) {
          this.fetchedBillingAddress = resp.data.address_line1;
          this.fetchedCountry = resp.data.country;
          this.fetchedZipCode = resp.data.postal_code;
          this.billingAddress = resp.data.address_line1;
          this.zipCode = resp.data.postal_code;
          this.country = resp.data.country;
          if (resp.data.state_code) {
            this.state_code = resp.data.state_code;
          } else {
            this.state_code = '';
          }
          if (resp.data.state) {
            this.state = resp.data.state;
          } else {
            this.state = '';
          }
          this.fetchedState = this.state;
          this.fetchedStateCode = this.state_code;
          await this.calculateTax(this.country, this.zipCode, this.state_code);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    await PaymentApi.methods
      .getPricingPlans()
      .then((resp) => {
        console.log(resp);
        this.pricingSlabsList = resp.pricing;
      })
      .catch((err) => console.log(err));
    this.loadingCards = false;
  },
  created() {
    this.numSeats = this.licenses;
  },

  watch: {
    processing(val) {
      if (val) {
        this.$modal.show(
          ProcessingPaymentDetail,
          {
            processing: this.processing,
          },
          {
            name: 'ProcessingPaymentDetail',
            width: '683px',
            height: '280px',
            clickToClose: false,
          },
        );
      } else {
        this.$modal.hide('ProcessingPaymentDetail');
        if (this.upgradeSuccess) {
          this.$modal.show(
            SuccessPaymentDetail,
            {
              isSignedOut: !this.isRenewal,
            },
            {
              name: 'SuccessPaymentDetail',
              width: '683px',
              height: this.isRenewal ? '655px' : '605px',
            },
          );
          if (!this.isRenewal) Auth.signOut();
        } else {
          this.$modal.show(
            FailedPaymentDetail,
            {
              errorMessage: this.paymentFailureMsg,
            },
            {
              name: 'FailedPaymentDetail',
              width: '683px',
              height: '555px',
            },
          );
        }
        this.handleClose(null, false);
      }
    },
  },
  methods: {
    ...mapActions('users', ['setCurrentUser']),
    ...mapActions('paymentMethods', ['setPaymentMethods']),

    getPlanDescription(text) {
      const [knowFeature] = this.currentUser.features.filter(
        (e) => e.feature_name === 'best-practice-library',
      );
      if (knowFeature && !knowFeature.enabled) {
        const detailsText = text
          .split('\n')
          .filter(
            (e) => e.toLowerCase().indexOf('to Best practice guides') === -1,
          );
        const detailsArray = detailsText.map(
          (line, index) =>
            `${line}${index === detailsText.length - 1 ? '' : '\n'}`,
        );
        return detailsArray;
        // return text
        //   .replace('\nLimited access to Know', '')
        //   .replace('\nFull access to Know', '');
      }
      const detailsText = text.split('\n');
      const detailsArray = detailsText.map(
        (line, index) =>
          `${line}${index === detailsText.length - 1 ? '' : '\n'}`,
      );
      // console.log(detailsArray);
      return detailsArray;
    },

    getHeader(item, currentSubscription) {
      const availablePlan = this.availablePlans.find((pl) => pl.id === item.id);
      if (
        currentSubscription &&
        currentSubscription.planID === '12346' &&
        availablePlan.id === '12347'
      ) {
        availablePlan.header = 'Everything in Professional plus:';
      }
      return availablePlan.header;
    },

    handleAutoRenewClick() {
      trackProfileEvents.profileAdminSettingsPaymentsDisclaimerBoxClick(
        this.currentUser,
        {
          [TD_PLACEMENT]: this.isRenewal ? TD_RENEW : TD_UPGRADE,
          [TD_COMMON_COLUMN_NAME]: TD_CLICK_STATUS,
          [TD_COMMON_COLUMN_VALUE]: this.saveCardDetailsOrAutoRenew
            ? TD_CHECKED
            : TD_UNCHECKED,
        },
      );
    },
    handleAgreeDisable() {
      if (!this.savedCardsExists) {
        return (
          !this.cardHolderName ||
          !this.billingAddress ||
          !this.zipCode ||
          !this.isPositiveInteger(this.numSeats) ||
          !this.country ||
          !this.totalPrice ||
          this.isCardElementError ||
          this.taxJarError ||
          this.zipCodeError ||
          this.stateError ||
          this.stateMissingButRequired() ||
          this.fetchingTaxRates ||
          this.cardHolderName.length > 30
        );
      }
      if (this.addingNewPaymentMethod === true) {
        return (
          !this.cardHolderName ||
          !this.billingAddress ||
          !this.zipCode ||
          !this.isPositiveInteger(this.numSeats) ||
          !this.country ||
          !this.totalPrice ||
          this.isCardElementError ||
          this.taxJarError ||
          this.zipCodeError ||
          this.stateError ||
          this.stateMissingButRequired() ||
          this.fetchingTaxRates ||
          this.cardHolderName.length > 30
        );
      }
      return (
        !this.billingAddress ||
        !this.zipCode ||
        !this.isPositiveInteger(this.numSeats) ||
        !this.country ||
        !this.totalPrice ||
        !this.selectedSavedCard ||
        this.taxJarError ||
        this.zipCodeError ||
        this.stateError ||
        this.stateMissingButRequired() ||
        this.fetchingTaxRates
      );
    },

    handleSelectAvailableCardsForPayment(card, action) {
      if (action === 'existingCard') {
        this.viewMode = 'cardSelected';
        this.selectedSavedCard = card;
        this.addingNewPaymentMethod = false;
      } else {
        this.selectedSavedCard = null;
        this.viewMode = '';
        this.addingNewPaymentMethod = true;
        this.isCardElementError = true;
      }
    },
    handleSwitchPaymentLayout() {
      this.selectedSavedCard = null;
      this.viewMode = 'cardList';
      this.addingNewPaymentMethod = false;
      trackProfileEvents.profileAdminSettingsPaymentsSelectaDifferentCard(
        this.currentUser,
        {
          [TD_PLACEMENT]: this.isRenewal ? TD_RENEW : TD_UPGRADE,
        },
      );
    },
    getInTouch() {
      try {
        const params = {
          type: 'buy-subscription',
          to: ['sfdc@prezent.ai'],
          meta: {
            firstName: this.currentUser.user.firstName,
            lastName: this.currentUser.user.lastName,
            userEmail: this.currentUser.user.id,
          },
        };
        sendEmail(params)
          .then((response) => console.log('sendEmail rsp ', response))
          .catch((err) => console.log('sendEmail err ', err));
      } catch (err) {
        console.log(err);
      }

      this.$modal.show(
        ContactSoonModal,
        {},
        {
          name: 'ContactSoonModal',
          width: '449px',
          height: '503px',
        },
      );
    },
    isPositiveInteger(n) {
      return n > 0;
    },
    getFinalPrice() {
      return Number(
        this.getTotalPrice() + this.getTotalPrice() * this.taxForCalculation,
      );
    },
    getTotalPrice() {
      if (this.numSeats <= 0) {
        this.totalPrice = 0;
        return this.totalPrice;
      }
      if (this.plan && ['12347', '12348', '12349'].includes(this.plan.id)) {
        this.totalPrice =
          Math.round(this.getBasePrice() * this.numSeats * 100) / 100;
        return this.totalPrice;
      }
      const price = this.getBasePrice();
      const licensePrice = price;
      const total = licensePrice * this.numSeats;
      this.totalPrice = Math.round(total * 100) / 100;
      return this.totalPrice;
    },
    getBasePrice() {
      if (!this.pricingSlabsList.length || this.numSeats === 0) return 0;
      let basePrice = 0;
      const priceSlab = this.pricingSlabsList.find(
        (price) => this.numSeats >= price.min && this.numSeats <= price.max,
      );
      if (!priceSlab) return basePrice;
      switch (this.plan.id) {
        case '12349':
          basePrice = priceSlab.premiumPrice;
          break;
        case '12348':
          basePrice = priceSlab.standardPrice;
          break;
        case '12347':
          basePrice = priceSlab.enterprisePrice;
          break;
        default:
          break;
      }
      return basePrice;
    },
    getPrevSubEndDate() {
      if (!this.isTrial && !this.currentActiveSubscription.isExpired) {
        return Date.parse(this.currentActiveSubscription.endDate);
      }
      return Date.now();
    },
    getStartDate() {
      const timeElapsed = this.getPrevSubEndDate();
      const today = new Date(timeElapsed);
      return dateFormat(today, 'mmmm dS, yyyy');
    },
    getEndDate() {
      const timeElapsed = this.getPrevSubEndDate();
      const endDate = new Date(timeElapsed + 365 * 24 * 60 * 60 * 1000);
      return dateFormat(endDate, 'mmmm dS, yyyy');
    },

    handleClose(e, captureAction = true) {
      if (captureAction) {
        const ecommerceAction = {
          operation: 'create-opportunity',
          StageName: 'Post-Demo Decision Pending',
          LeadSource: this.isRenewal
            ? 'Prezent app- Renew'
            : 'Prezent app- Upgrade',
        };
        ECommerceActionsApi.methods
          .registerAction(ecommerceAction)
          .catch((err) => {
            console.log(err);
          });
      }
      this.$modal.hide('SubmitPaymentDetail');
    },

    async handleSubscribe() {
      if (this.isRenewal) {
        trackProfileEvents.profileAdminSettingsRenewAgreeAndRenew(
          this.currentUser,
          {
            [TD_TEAM]: this.currentUser?.team?.name,
          },
        );
      } else {
        trackProfileEvents.profileAdminSettingsUpgradeAgreeAndSubscribe(
          this.currentUser,
          {
            [TD_TEAM]: this.currentUser?.team?.name,
          },
        );
      }
      if (!this.isRenewal) {
        ECommerceActionsApi.methods
          .registerAction({
            operation: 'create-lead',
            callAction: 'upgrade',
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        const ecommerceAction = {
          operation: 'create-opportunity',
          StageName: 'SOW Sent',
          LeadSource: 'Prezent app- Renew',
        };
        ECommerceActionsApi.methods
          .registerAction(ecommerceAction)
          .catch((err) => {
            console.log(err);
          });
      }
      this.processing = true;
      const billingDetails = {
        name: this.cardHolderName,
        email: this.currentUser.user.id,
      };

      if (this.isRenewal) {
        if (this.selectedSavedCard) {
          // renew with saved card
          await PaymentApi.methods
            .renewSubscription({
              paymentMethodID: this.selectedSavedCard.id,
              subscriptionID: this.subscriptionID,
              saveAndRenew: this.saveCardDetailsOrAutoRenew,
              address_line1: this.billingAddress,
              state_code: this.state_code ? this.state_code : undefined,
              state: this.state ? this.state : undefined,
              country: this.country,
              postal_code: this.zipCode,
              isExistingPaymentMethod: true,
              isExistingBillingAddress: this.isExistingBillingAddress,
            })
            .then((resp) => {
              const userInfo = this.currentUser;
              userInfo.subscriptions = [resp];
              this.setCurrentUser(userInfo);
              this.processing = false;
              this.upgradeSuccess = true;
            })
            .catch((error) => {
              console.log(
                'error while calling renew subscription',
                error.response,
              );
              this.upgradeSuccess = false;
              this.paymentFailureMsg = 'Please try after sometime.';
              this.processing = false;
            });
        } else {
          // add new card and renew
          const { stripe } = this.$refs.stripeElementCard;
          await stripe
            .createPaymentMethod({
              type: 'card',
              card: this.$refs.stripeElementCard.element,
              billing_details: billingDetails,
            })
            .then(async (paymentMethodRes) => {
              const { paymentMethod } = paymentMethodRes;
              await PaymentApi.methods
                .renewSubscription({
                  paymentMethodID: paymentMethod.id,
                  subscriptionID: this.subscriptionID,
                  saveAndRenew: this.saveCardDetailsOrAutoRenew,
                  address_line1: this.billingAddress,
                  state_code: this.state_code ? this.state_code : undefined,
                  state: this.state ? this.state : undefined,
                  country: this.country,
                  postal_code: this.zipCode,
                  isExistingPaymentMethod: false,
                  isExistingBillingAddress: this.isExistingBillingAddress,
                })
                .then((resp) => {
                  const userInfo = this.currentUser;
                  userInfo.subscriptions = [resp];
                  this.setCurrentUser(userInfo);
                  this.processing = false;
                  this.upgradeSuccess = true;
                })
                .catch((error) => {
                  console.log(
                    'error while calling renew subscription',
                    error.response,
                  );
                  this.upgradeSuccess = false;
                  this.paymentFailureMsg = 'Please try after sometime.';
                  this.processing = false;
                });
            })
            .catch((stripeError) => {
              console.log('error while calling create payment', stripeError);
              this.upgradeSuccess = false;
              this.paymentFailureMsg = 'Please try after sometime.';
              this.processing = false;
            });
        }
      } else if (this.selectedSavedCard) {
        // upgrade with saved card
        await PaymentApi.methods
          .upgradeSubscription({
            paymentMethodID: this.selectedSavedCard.id,
            subscriptionID: this.subscriptionID,
            numSeats: this.numSeats,
            saveAndRenew: this.saveCardDetailsOrAutoRenew,
            address_line1: this.billingAddress,
            state_code: this.state_code ? this.state_code : undefined,
            state: this.state ? this.state : undefined,
            country: this.country,
            postal_code: this.zipCode,
            isExistingPaymentMethod: true,
            isExistingBillingAddress: this.isExistingBillingAddress,
            planId: this.plan.id,
          })
          .then((resp) => {
            console.log(resp);
            this.processing = false;
            this.upgradeSuccess = true;
          })
          .catch((error) => {
            console.log(
              'error while calling renew subscription',
              error.response,
            );
            this.upgradeSuccess = false;
            this.paymentFailureMsg = 'Please try after sometime.';
            this.processing = false;
          });
      } else {
        // add new card and upgrade
        const { stripe } = this.$refs.stripeElementCard;
        await stripe
          .createPaymentMethod({
            type: 'card',
            card: this.$refs.stripeElementCard.element,
            billing_details: billingDetails,
          })
          .then(async (paymentMethodRes) => {
            const { paymentMethod } = paymentMethodRes;
            await PaymentApi.methods
              .upgradeSubscription({
                paymentMethodID: paymentMethod.id,
                subscriptionID: this.subscriptionID,
                numSeats: this.numSeats,
                saveAndRenew: this.saveCardDetailsOrAutoRenew,
                address_line1: this.billingAddress,
                state_code: this.state_code ? this.state_code : undefined,
                state: this.state ? this.state : undefined,
                country: this.country,
                postal_code: this.zipCode,
                isExistingPaymentMethod: false,
                isExistingBillingAddress: this.isExistingBillingAddress,
                planId: this.plan.id,
              })
              .then((resp) => {
                console.log(resp);
                this.processing = false;
                this.upgradeSuccess = true;
              })
              .catch((error) => {
                console.log(
                  'error while calling renew subscription',
                  error.response,
                );
                this.upgradeSuccess = false;
                this.paymentFailureMsg = 'Please try after sometime.';
                this.processing = false;
              });
          })
          .catch((stripeError) => {
            console.log('error while calling create payment', stripeError);
            this.upgradeSuccess = false;
            this.paymentFailureMsg = 'Please try after sometime.';
            this.processing = false;
          });
      }

      // fetch new payment methods
      getSavedPaymentMethods()
        .then((resp) => {
          if (resp?.data && resp?.data.length) {
            const cardsInfo = resp.data.map((card) => ({
              ...card,
            }));
            this.setPaymentMethods(cardsInfo);
          } else {
            this.setPaymentMethods([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    stripeElementCardChanged(event) {
      console.log(event);
      if (event && (event.error || event.empty || !event.complete)) {
        this.isCardElementError = true;
      } else {
        this.isCardElementError = false;
      }
    },

    getZipCodeMaxLength() {
      if (this.country === 'US') {
        return 5;
      }
      if (this.country === 'CA') {
        return 7;
      }
      return 10;
    },

    async isZipValidAsPerCountry() {
      // this.zipCodeError = false;
      // this.taxJarError = false;
      /* eslint-disable prefer-regex-literals */
      const usCodes = new RegExp('^\\d{5}(-{0,1}\\d{4})?$');
      const caCodes = new RegExp(
        /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
      );
      if (this.country === 'US') {
        if (usCodes.test(this.zipCode.toString())) {
          this.zipCodeError = false;
          if (this.state_code) {
            await this.calculateTax(
              this.country,
              this.zipCode,
              this.state_code,
            );
          }
        } else {
          this.taxToBeApplied = 0;
          this.taxForCalculation = 0;
          this.zipCodeError = true;
        }
      } else if (this.country === 'CA') {
        if (caCodes.test(this.zipCode.toString())) {
          this.zipCodeError = false;
          if (this.state_code) {
            await this.calculateTax(
              this.country,
              this.zipCode,
              this.state_code,
            );
          }
        } else {
          this.taxToBeApplied = 0;
          this.taxForCalculation = 0;
          this.zipCodeError = true;
        }
      }
    },

    async calculateTax(country, zip, stateCode) {
      this.fetchingTaxRates = true;
      await getTaxRate(country, stateCode, zip)
        .then((resp) => {
          const rate = resp?.data?.rate;
          this.taxToBeApplied = Number(Number(rate * 100).toFixed(3));
          this.taxForCalculation = rate;
          this.zipCodeError = false;
          this.stateError = false;
          this.taxJarError = false;
        })
        .catch((err) => {
          // this.zipCodeError = true;
          if (err?.response?.status === 500 || err?.response?.status === 503) {
            this.taxToBeApplied = 0;
            this.taxForCalculation = 0;
            this.taxJarError = false;
            this.zipCodeError = false;
            this.stateError = false;
          } else {
            this.taxJarError = true;
          }
        });
      this.fetchingTaxRates = false;
    },

    async countryChanged(newCountry) {
      // resetting all tax flags
      this.taxToBeApplied = 0;
      this.taxForCalculation = 0;
      this.taxJarError = false;
      this.zipCodeError = false;
      this.stateError = false;
      this.state = '';
      this.state_code = '';
      this.zipCode = '';
      this.country = newCountry;
      if (this.country !== 'US' && this.country !== 'CA') {
        // calculate tax for other supported countries
        this.zipCode = '';
        await this.calculateTax(newCountry);
      }
    },

    stateChanged(newState, state) {
      // resetting tax flags
      this.taxJarError = false;
      this.zipCodeError = false;
      this.zipCode = '';
      this.state = state.name;
      this.state_code = newState;
      this.stateError = false;
      if (this.country === 'US' || this.country === 'CA') {
        this.taxToBeApplied = 0;
        this.taxForCalculation = 0;
        if (newState === '') {
          this.stateError = true;
        }
      }
    },

    stateMissingButRequired() {
      return (
        (this.country === 'US' || this.country === 'CA') &&
        this.state_code === ''
      );
    },

    formatAmountWithCommas(num) {
      if (num % 1 === 0)
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return num
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.submit-payment-wrapper {
  width: 100%;
  height: 100%;
  padding: 20px 24px 10px 24px;
  overflow: scroll;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .spinner {
    padding: 18px 0px;
    text-align: center;
    margin: 350px 0px 0px 0px;
  }

  .contentContainer {
    .submit-payment-wrapper-title {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      margin-bottom: 18px;
    }

    .submit-payment-content-wrapper {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .submit-payment-info {
        width: 55%;
        .submit-payment-info-title {
          font-size: 22px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          margin-bottom: 16px;
        }

        .paymentMethodsCardsContainer {
          .selectedCardMode {
            .selectedCardModeHeading {
              padding: 0px 0px 10px 0px;
              font-size: 20px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: black;
            }
            .selectedCardInfoAndLayout {
              height: 115px;
              // width: 65%;
              padding: 0px 0px 24px 0px;
              ::v-deep .savedCards {
                .iconContainer {
                  padding: 10px 12px;
                }
                .otherCardInfo {
                  width: 70% !important;
                  .maskedCardNumber {
                    .mask {
                      padding: 7px 0px 0px 0px;
                    }
                  }
                }
                .cardActionsAndStatus {
                  width: 5% !important;
                }
              }
            }
          }
          .paymentCardsContainer {
            .paymentCardsContainerHeading {
              padding: 0px 0px 10px 0px;
              font-size: 20px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: black;
            }
            .paymentCardsContainerList {
              display: grid;
              grid-template-columns: 1fr;
              grid-row-gap: 24px;
              grid-column-gap: 24px;
              max-height: 360px;
              overflow: auto;
              padding: 8px 8px 8px 8px;
              .paymentCardsListItems {
                cursor: pointer;
                ::v-deep .savedCards {
                  .otherCardInfo {
                    width: 70% !important;
                    .maskedCardNumber {
                      .mask {
                        padding: 7px 0px 0px 0px;
                      }
                    }
                  }
                  .cardActionsAndStatus {
                    width: 5% !important;
                  }
                }
              }
              .addNewPaymentCard {
                cursor: pointer;
                display: flex;
                padding: 24px;
                border-radius: 16px;
                box-shadow: 0 0 4px 0 rgb(0 0 0 / 15%);
                .cardTitle {
                  font-size: 18px;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  padding: 10px 5px;
                }
                &:hover {
                  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.5);
                }
              }
            }
          }
        }

        .noCardsPresent {
          width: 100%;
          .new-card-addition-title {
            font-size: 18px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            margin-bottom: 16px;
          }
          .stripeCardAndName {
            // display: flex;
            width: 100%;
            justify-content: space-between;
            .credit-card-wrapper {
              margin-bottom: 10px;
              height: 90px;
              .credit-card-title {
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                margin-bottom: 10px;
              }
              ::v-deep #stripe-element-form {
                // width: 424px;
                .StripeElement {
                  padding: 10px 12px;
                  background-color: #fff;
                  border: 1px solid transparent;
                  border-radius: 24px;
                  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
                }
                #stripe-element-errors {
                  color: #fa755a;
                }
              }
            }
            .names-wrapper {
              margin-bottom: 16px;
              height: 90px;
              .name-wrapper {
                .name-wrapper-title {
                  font-size: 16px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: black;
                  margin-bottom: 10px;
                }

                input {
                  width: 100%;
                  height: 40px;
                  padding: 10px 12px;
                  background-color: #fff;
                  border: 1px solid transparent;
                  border-radius: 24px;
                  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
                }
                .nameError {
                  color: red;
                }
              }
            }
          }
        }

        .billing-address-wrapper {
          margin-bottom: 26px;

          .billing-address-title {
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: black;
            margin-bottom: 10px;
          }

          input {
            width: 100%;
            height: 40px;
            padding: 10px 12px;
            background-color: #fff;
            border: 1px solid transparent;
            border-radius: 24px;
            box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
          }
        }

        .zip-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;

          .zip-code-wrapper {
            width: 48%;
            height: 70px;
            .zip-code-wrapper-title {
              font-size: 16px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              margin-bottom: 10px;
            }

            input {
              width: 100%;
              height: 40px;
              padding: 10px 12px;
              background-color: #fff;
              border: 1px solid transparent;
              border-radius: 24px;
              box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
            }

            .zipError {
              color: red;
              height: 69px;
            }
          }
        }

        .state-country-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;

          .country-wrapper {
            width: 48%;
            .country-wrapper-title {
              font-size: 16px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              margin-bottom: 10px;
            }

            select {
              width: 100%;
              height: 40px;
              position: relative;
              font-size: 20px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              padding: 10px 12px;
              background-color: #fff;
              border: 1px solid transparent;
              border-radius: 24px;
              box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
            }
          }
          .state-wrapper {
            width: 48%;
            .state-wrapper-title {
              font-size: 16px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              margin-bottom: 10px;
            }
            .stateError {
              color: red;
            }

            select {
              width: 100%;
              height: 40px;
              position: relative;
              font-size: 20px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              padding: 10px 12px;
              background-color: #fff;
              border: 1px solid transparent;
              border-radius: 24px;
              box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
            }
          }
        }

        .switchLayouts {
          padding: 15px 0px 0px 0px;
          .v-btn {
            height: 20px;
            background: transparent;
            background-color: transparent;
            box-shadow: none;
            padding: 0;
            text-transform: none;
            font-size: 16px;
            letter-spacing: normal;
            color: #20a7e0;
            cursor: pointer;
            &:hover {
              background-color: transparent !important;
            }
            &:before {
              background-color: transparent !important;
            }
          }
        }

        .poweredByStripe {
          margin: 20px 0px 0px 0px;
        }
      }

      .your-order-info {
        .your-order-info-title {
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          margin-bottom: 19px;
        }

        .your-order-info-content {
          padding: 28px 17px 26px 17px;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
          width: 312px;
          border-radius: 16px;

          .your-order-info-title {
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            margin-bottom: 15px;
            color: black;
          }

          .your-order-info-content-image {
            margin-bottom: 13px;
            display: flex;

            img {
              margin: auto;
            }
          }

          .your-order-info-content-title {
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #7b7b7b;
          }

          .your-order-info-content-sub-title {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #7b7b7b;
            margin-bottom: 13px;
          }

          .subscription-wrapper-header {
            text-align: center !important;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            height: 8px;
            margin-bottom: 16px;
          }

          .subscription-wrapper-detail {
            // align-items: flex-start;
            // display: flex;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            // justify-content: flex-start;
            line-height: 26px;
            letter-spacing: normal;
            text-align: left;
            // height: 100px;
            // white-space: pre-wrap;
            // margin-top: 10px;
            .detailItem {
              display: flex;
              .tickContainer {
                min-width: 16px;
              }
              .detailText {
                margin: 0px 0px 0px 2px;
              }
            }
          }

          .your-order-info-content-sub-desc {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            margin-bottom: 20px;
            white-space: pre-wrap;
          }

          .your-oder-info-content-subscript-title {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            margin-bottom: 5px;
            margin-top: 20px;
          }

          .your-oder-info-content-subscript-value {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            margin-bottom: 20px;
          }

          .your-oder-info-content-subscript-input-title {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            margin-bottom: 9px;
          }

          .your-oder-info-content-subscript-input-value {
            display: flex;
            align-items: center;
            justify-content: center;

            input {
              width: 81px;
              height: 40px;
              border-radius: 8px;
              box-shadow: inset 0 1px 3px 0 #050708;
              text-align: center;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
            }
          }
        }
      }
    }

    .total-prices-info {
      width: 100%;
      margin-top: 24px;
      margin-bottom: 15px;

      .total-prices-info-total-and-save-card {
        display: flex;
        width: 100%;
        .saveCheckbox {
          width: 50%;
          ::v-deep .v-input {
            margin: 0 !important;
            padding: 0 !important;
            .v-input__control {
              .v-input__slot {
                .v-label {
                  padding: 0px 0px 0px 4px;
                }
              }
            }
          }
        }
        .total-prices-info-total {
          width: 50%;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: right;
          color: #7b7b7b;
          margin-bottom: 5px;
          color: black;
        }
      }

      .total-prices-info-sub,
      .total-prices-info-tax {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #7b7b7b;
        margin-bottom: 5px;
      }

      .taxesCalculateError,
      .validZipCodeRequest {
        text-align: right;
        font-size: 14px;
        line-height: 20px;
        color: red;
      }
    }

    .submit-payment-terms-privacy {
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      margin-bottom: 24px;

      a {
        color: #21a7e0;
      }
    }

    .submit-payment-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        height: 46px !important;
        width: 292px !important;
        font-size: 22px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-transform: none;
        color: white;
      }

      .submit-payment-actions-right-title {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: black;
      }

      .submit-payment-actions-right-link a {
        text-align: right;
        color: #21a7e0;
      }
    }
  }
}
</style>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
