import { trackMatomoEvent } from './MatomoAnalyticsHandler';
import {
  getMatomoEventName,
  getSlideData,
  trackCommonEvent,
} from './MatomoTrackingDataHelper';

const KNOW_EVENT_CATEGORY = 'Know';
const KNOW_ICONCLICK = 'know_IconClick';
const KNOW_SEEMORERESULTS = 'know_SeeMoreResults';
const KNOW_THUMBNAIL_DOWNLOAD = 'Know_Thumbnail_download';
const KNOW_THUMBNAILCLICK = 'know_ThumbnailClick';
const KNOW_THUMBNAILCLICK_DOWNLOAD = 'know_ThumbnailClick_download';
const KNOW_THUMBNAIL_VIEWICONCLICK = 'know_Thumbnail_ViewIconClick';
const KNOW_THUMBNAIL_VIEWICONCLICK_DOWNLOAD =
  'know_Thumbnail_ViewIconClick_download';
const KNOW_SORTBY = 'know_SortBy';
const KNOW_TYPEDROPDOWN = 'know_TypeDropdown';
const KNOW_SUGGEST = 'know_Suggest';
const KNOW_SUGGEST_SUBMIT = 'know_Suggest_Submit';
const KNOW_BESTPRACTICE = 'know_BestPractice';
const KNOW_IDEACHIPCLICK = 'know_ideachipclick';
const KNOW_SIMILARSLIDES = 'know_SimilarSlides';
const KNOW_SIMILARSLIDES_DOWNLOAD = 'know_SimilarSlides_download';
const KNOW_DECKS_SEARCH = 'Know_decks_Search';
const KNOW_SLIDES_SEARCH = 'Know_slides_Search';
const KNOW_ADVACNE_FILTERS_SELECTED = 'know_advance-filters_selected';

export const trackKnowEvents = {
  knowIconClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_ICONCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  knowSeeMoreResults(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_SEEMORERESULTS,
      });
    } catch (e) {
      console.log(e);
    }
  },
  know(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(KNOW_EVENT_CATEGORY, KNOW_THUMBNAIL_DOWNLOAD, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  knowThumbnailClickDownload(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      const eventAction =
        slideData?.origin === 'viewicon'
          ? KNOW_THUMBNAIL_VIEWICONCLICK_DOWNLOAD
          : KNOW_THUMBNAILCLICK_DOWNLOAD;
      trackMatomoEvent(KNOW_EVENT_CATEGORY, eventAction, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  knowThumbnailClick(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      const eventAction =
        slideData?.origin === 'viewicon'
          ? KNOW_THUMBNAIL_VIEWICONCLICK
          : KNOW_THUMBNAILCLICK;
      trackMatomoEvent(KNOW_EVENT_CATEGORY, eventAction, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  knowTypeDropDown(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_TYPEDROPDOWN,
      });
    } catch (e) {
      console.log(e);
    }
  },

  knowSortType(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_SORTBY,
      });
    } catch (e) {
      console.log(e);
    }
  },

  knowSuggest(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_SUGGEST,
      });
    } catch (e) {
      console.log(e);
    }
  },

  knowSuggestSubmit(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_SUGGEST_SUBMIT,
      });
    } catch (e) {
      console.log(e);
    }
  },

  knowBestPractice(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(KNOW_EVENT_CATEGORY, KNOW_BESTPRACTICE, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  trackKnowIdeachipClick(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_IDEACHIPCLICK,
      });
    } catch (e) {
      console.log(e);
    }
  },
  knowSimilarSlides(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(KNOW_EVENT_CATEGORY, KNOW_SIMILARSLIDES, eventName);
    } catch (e) {
      console.log(e);
    }
  },
  knowSimilarSlidesDownload(slideData, currentUser, otherData) {
    try {
      const trackingData = getSlideData(slideData, currentUser, otherData);
      const eventName = getMatomoEventName(trackingData);
      trackMatomoEvent(
        KNOW_EVENT_CATEGORY,
        KNOW_SIMILARSLIDES_DOWNLOAD,
        eventName,
      );
    } catch (e) {
      console.log(e);
    }
  },
  knowDeckSearch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_DECKS_SEARCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  knowSlideSearch(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_SLIDES_SEARCH,
      });
    } catch (e) {
      console.log(e);
    }
  },
  knowAdvanceFiltersSelected(currentUser, otherData) {
    try {
      trackCommonEvent({
        currentUser,
        otherData,
        eventCategory: KNOW_EVENT_CATEGORY,
        eventAction: KNOW_ADVACNE_FILTERS_SELECTED,
      });
    } catch (e) {
      console.log(e);
    }
  },
};
