<template>
  <div class="tmpDesignerCompleteBannerCntnr">
    <div class="banner_content">
      <div class="textContent">
        {{ $t('templateDesign.banners.completionText') }}
      </div>
      <div class="navCTA">
        <v-btn @click="handleSelectTheme" :loading="changingTheme">{{
          $t('templateDesign.banners.selectTemplate')
        }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import usersApi from '../../../API/users-api';
import TemplateCompleteConfirmationPopup from '../TemplateCompleteConfirmationPopup.vue';

export default {
  name: 'TemplateDesignerCompleteBanner',
  data() {
    return {
      changingTheme: false,
    };
  },
  computed: {
    ...mapState('users', ['currentUser', 'themes']),
  },
  methods: {
    ...mapActions('users', ['setCurrentUser', 'setCurrentTheme']),
    async handleSelectTheme() {
      const designTheme = this.themes.filter((item) => item.isUserUploaded);
      if (designTheme.length) {
        const theme = designTheme[0];
        this.changingTheme = true;
        try {
          await usersApi.methods
            .updateUserProfile({ defaultTheme: theme.code })
            .then(async (updatedUserDetail) => {
              const userInfo = this.currentUser;
              userInfo.user = { ...userInfo.user, ...updatedUserDetail };
              userInfo.theme = theme;
              await this.setCurrentUser(userInfo);
            });
          this.setCurrentTheme(theme);
          this.changingTheme = false;
          this.$modal.show(
            TemplateCompleteConfirmationPopup,
            {},
            {
              name: 'TemplateCompleteConfirmationPopup',
              width: '400px',
              height: '380px',
              styles: {
                borderRadius: '12px',
              },
            },
          );
        } catch (err) {
          this.changingTheme = false;
          console.error(err);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tmpDesignerCompleteBannerCntnr {
  height: 48px;
  background: #e1f5fb;
  .banner_content {
    font-size: 16px;
    font-weight: 500;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .navCTA {
      padding: 0px 16px;
      .v-btn {
        text-decoration: none;
        letter-spacing: normal;
        text-transform: none;
        background-color: transparent;
        color: #21a7e0;
        box-shadow: none;
        padding: 0;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
</style>
