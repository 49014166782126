<template>
  <div class="usrDwnldPrefSelectorContainer">
    <div class="closeIcon" v-if="compType === 'modal'">
      <v-icon size="20" @click="handleModalClose">{{ 'mdi-close' }}</v-icon>
    </div>
    <div class="heading">{{ $t('common.downloadSettingsText') }}</div>
    <div class="subText">
      {{ $t('common.downloadSettingsPopupSubText') }}
    </div>
    <div class="radioOptions">
      <v-radio-group
        :value="getUserDownloadPreference"
        data-pendo-id="default-download-options"
        @change="(e) => handleRadioButtonChange(e)"
      >
        <v-radio
          v-for="(opt, ind) in radioOptions"
          :key="ind"
          :value="opt.value"
          :label="$t(opt.label)"
          color="#21a7e0"
        />
      </v-radio-group>
    </div>
    <div
      :class="compType === 'modal' ? 'ctaContainerModal' : 'ctaContainerComp'"
    >
      <v-btn
        :disabled="disableSaveBtn"
        @click="handleUpdateDownloadPreference"
        data-pendo-id="save-default-download-settings"
        :loading="saving"
      >
        {{ $t(saveText) }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import usersApi from '../../API/users-api';

export default {
  name: 'UserDownloadPreferenceSelector',
  data() {
    return {
      saving: false,
      preferenceOnLoad: '',
      saveText: 'common.save',
      radioOptions: [
        {
          label: 'common.downloadSettAlwaysAsk',
          value: 'always_ask',
        },
        {
          label: 'common.downloadSettLocalDownload',
          value: 'local_download',
        },
        {
          label: 'common.downloadSettSaveToGdrive',
          value: 'save_to_gdrive',
        },
      ],
    };
  },
  props: {
    compType: {
      type: String,
      default: 'modal',
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', ['getUserDownloadPreference']),

    disableSaveBtn() {
      return this.getUserDownloadPreference === this.preferenceOnLoad;
    },
  },
  methods: {
    ...mapActions('users', ['setUserDownloadPreference', 'setCurrentUser']),

    handleModalClose() {
      this.$modal.hide('UserDownloadPreferenceSelector');
    },

    handleRadioButtonChange(e) {
      if (e && e !== '') {
        this.setUserDownloadPreference(e.toLowerCase());
      }
    },

    async handleUpdateDownloadPreference() {
      this.saving = true;
      const userdata = {
        userDownloadPreference: this.getUserDownloadPreference,
      };
      await usersApi.methods
        .updateUserProfile(userdata)
        .then((resp) => {
          this.saving = false;
          this.saveText = 'common.saved';
          setTimeout(() => {
            let userInfo = this.currentUser;
            userInfo = {
              ...userInfo,
              user: {
                ...userInfo.user,
                userDownloadPreference: resp.userDownloadPreference,
              },
            };
            this.setCurrentUser(userInfo);

            this.saveText = 'common.save';
            if (this.compType !== 'modal') {
              this.preferenceOnLoad = resp.userDownloadPreference;
            } else {
              this.handleModalClose();
            }
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.saving = false;
        });
    },
  },
  mounted() {
    this.setUserDownloadPreference(
      this.currentUser?.user?.userDownloadPreference?.toLowerCase(),
    );
    this.preferenceOnLoad =
      this.currentUser?.user?.userDownloadPreference?.toLowerCase();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.usrDwnldPrefSelectorContainer {
  padding: 16px 24px 32px 24px;
  .closeIcon {
    text-align: right;
  }
  .heading {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
  }
  .subText {
    padding: 16px 0px;
    font-size: 16px;
    text-align: left;
  }
  .radioOptions {
    padding: 20px 0px;
    ::v-deep .v-input {
      margin: 0;
      padding: 0;
      .v-input__control {
        .v-input__slot {
          margin: 0;
          .v-input--radio-group__input {
            .v-radio {
              margin: 0;
              padding: 0px 0px 12px 0px;
              .v-input--selection-controls__input {
                margin: 0px 4px 0px 0px;
              }
              .v-label {
                color: black;
              }
            }
          }
        }
        .v-messages {
          display: none;
        }
      }
    }
  }
  .ctaContainerModal,
  .ctaContainerComp {
    .v-btn {
      box-shadow: none;
      text-transform: none;
      letter-spacing: normal;
      border-radius: 20px;
      width: 80px;
      font-size: 16px;
      color: #ffffff;
      background-color: $zen-blue-default;
    }
  }
  .ctaContainerModal {
    display: flex;
    flex-direction: row;
    justify-content: right;
  }
  .ctaContainerComp {
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
}
</style>
